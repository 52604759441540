import { FormGroup, ValidationErrors } from '@angular/forms';

export const phonesMatch = (formGroup: FormGroup): ValidationErrors => {
    const phone = formGroup.get('phone');
    const phoneConfirmation = formGroup.get('phone_confirmation');

    if (!phone?.value && !phoneConfirmation?.value) {
        return {};
    }

    if (phone?.value === phoneConfirmation?.value || !phone?.value) {
        phoneConfirmation?.setErrors(null);
    } else {
        phoneConfirmation?.setErrors({
            phonesDoNotMatch: true
        });
    }

    return {
        ...phoneConfirmation?.errors
    };
};
