import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AutocompletePickerModule } from '@athlete-x/forms/autocomplete-picker';

import { FiltersComponent } from './components/filters/filters.component';
import { DirectoryComponent } from './components/directory/directory.component';
import { AdvancedFiltersComponent } from './components/advanced-filters/advanced-filters.component';
import { SharedModule } from '@athlete-x/shared';

import { TeamBannerModule, UserAvatarModule } from '@athlete-x/ui';
import { DirectoryItemComponent } from './components/directory-item/directory-item.component';
import { IndustriesPickerModule } from '@athlete-x/forms/industries-picker';
import { AppliedFiltersComponent } from './components/applied-filters/applied-filters.component';
import { MessageBoardModule } from '../message-board';
import { SendMessageComponent } from './components/send-message/send-message.component';
import { AutosizeModule } from 'ngx-autosize';
import { QuickFiltersComponent } from './components/quick-filters/quick-filters.component';
import { JoinPipe } from '@athlete-x/shared/pipes/join.pipe';
import { TeamBrandingComponent } from './components/team-branding/team-branding.component';
import { JobPickerModule } from '@athlete-x/forms/job-picker';
import { SchoolAutocompleteModule } from '@athlete-x/forms';

@NgModule({
    declarations: [
        DirectoryComponent,
        FiltersComponent,
        AdvancedFiltersComponent,
        DirectoryItemComponent,
        AppliedFiltersComponent,
        QuickFiltersComponent,
        SendMessageComponent,
        TeamBrandingComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        ScrollingModule,
        FormsModule,
        ReactiveFormsModule,
        AutocompletePickerModule,
        IndustriesPickerModule,
        SharedModule,
        TeamBannerModule,
        MessageBoardModule,
        AutosizeModule,
        JoinPipe,
        JobPickerModule,
        UserAvatarModule,
        SchoolAutocompleteModule
    ],
    exports: [
        DirectoryComponent,
        FiltersComponent,
        AdvancedFiltersComponent,
        DirectoryItemComponent,
        AppliedFiltersComponent,
        QuickFiltersComponent,
        SendMessageComponent,
        TeamBrandingComponent
    ]
})
export class DirectoryModule { }
