import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { IonicModule } from '@ionic/angular';
import { JoinPipe } from '@athlete-x/shared/pipes/join.pipe';
import { JobPickerComponent } from './components/job-picker/job-picker';
import { JobPickerService } from './services/job-picker.service';

@NgModule({
    declarations: [
        JobPickerComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AutocompleteLibModule,
        JoinPipe
    ],
    exports: [
        JobPickerComponent
    ],
    providers: [
        JobPickerService
    ]
})
export class JobPickerModule {
}
