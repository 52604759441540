import { inject, Injectable } from '@angular/core';
import { AUTOCOMPLETE_ENDPOINTS, Position, UserType } from '@athlete-x/definitions';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs';

@Injectable()
export class PositionService {
    private _http = inject(HttpClient);

    public fetchItems(userType: UserType, teamId?: number, sportId?: number) {
        const params: any = {
            user_type: (userType === UserType.ALUMNI) ? UserType.PLAYER : userType,
        };
        if (teamId) {
            params['team_id'] = teamId;
        }
        if (sportId) {
            params['sport_id'] = sportId;
        }
        return this._http.get<{ data: Array<Position> }>(AUTOCOMPLETE_ENDPOINTS.positions, {
                params
            })
            .pipe(map((data) => data?.data || data));
    }

}
