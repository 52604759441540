import { Inject, Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { InjectionToken } from '@angular/core';

export const API_BASE_ENDPOINT = new InjectionToken<string>('Base for all API endpoints');

@Injectable()
export class ApiBaseInterceptor implements HttpInterceptor {

    constructor(@Inject(API_BASE_ENDPOINT) private _apiBase: string) {
    }

    intercept(req: HttpRequest<never>, next: HttpHandler) {
        if (!this._apiBase) {
            console.error('API_BASE_ENDPOINT token not provided');
        }

        if (req.url.indexOf('http') === 0) {
            return next.handle(req);

        }

        const apiReq = req.clone({ url: this._apiBase + req.url });
        return next.handle(apiReq);
    }
}
