import { Pipe, PipeTransform } from '@angular/core';
import { Team } from '@athlete-x/definitions';

@Pipe({
    name: 'sortByStatus'
})
export class SortByStatusPipe implements PipeTransform {

    transform(teams: Array<Team>): Array<Team> {
        if (!teams?.length) {
            return teams;
        }

        return [
            ...teams.filter((t) => t.status === 'active'),
            ...teams.filter((t) => t.status !== 'active'),
        ];
    }

}
