import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { debounceTime, map, of, switchMap } from 'rxjs';
import { AutocompleteItem, AutocompleteType, AUTOCOMPLETE_TEXT_MAP } from '@athlete-x/definitions';
import { ModalController } from '@ionic/angular';
import { AutocompleteService } from '../../services/autocomplete.service';

@Component({
    selector: 'atx-autocomplete-modal',
    templateUrl: './autocomplete-modal.html',
    styleUrls: ['./autocomplete-modal.scss'],
})

export class AutocompleteModalComponent implements OnInit {
    @Input() type!: AutocompleteType;
    @Input() placeholder = 'Enter search string (min. 3 chars)';
    @Input() allowCreation = false;

    public autocompleteItems: Array<AutocompleteItem> = [];

    public autocompleteTextMap = AUTOCOMPLETE_TEXT_MAP;

    public form = this._formBuilder.group({
        selectedName: [''],
        selectedId: [0],
    });

    public get selectedNameControl() { return this.form.controls.selectedName; }

    constructor(
        private _modalCtrl: ModalController,
        private _formBuilder: FormBuilder,
        private _autocompleteService: AutocompleteService
    ) {
    }

    ngOnInit() {
        this.selectedNameControl
            .valueChanges
            .pipe(
                debounceTime(500),
                map((name: string | null) => (name && name?.length >= 3) ? name : ''),
                switchMap((name) => name ? this._autocompleteService.fetchItems(name, this.type, this.allowCreation) : of([]))
            )
            .subscribe((items) => this.autocompleteItems = items);
    }

    public onItemClick(item: AutocompleteItem) {
        this._confirm(item);
    }

    public cancel() {
        return this._modalCtrl.dismiss(null, 'cancel');
    }

    private _confirm(item: AutocompleteItem) {
        return this._modalCtrl.dismiss(item, 'confirm');
    }

}
