import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { ToastController } from '@ionic/angular';

const TOKEN_KEY = 'AthleteXAuthToken';

const PASSWORD_RESET_REQUEST = 'forgotten_password';

const RESET_PASSWORD = 'reset_password';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _window: Window;
    private _token!: string | null;
    public get token() {
        return this._token;
    }

    private _isAuthenticated$ = new BehaviorSubject<boolean>(false);
    public isAuthenticated = false;
    public isAuthenticated$ = this._isAuthenticated$
        .asObservable()
        .pipe(tap((a) => {
            this.isAuthenticated = a;
            console.log('AUTHENTICATED', a);
        }));
    // public get isAuthenticated() { return this._isAuthenticated$.value; }

    private _justAuthenticated$ = new Subject<string>();
    public justAuthenticated$ = this._justAuthenticated$.asObservable();

    private _justRegistered$ = new Subject<string>();
    public justRegistered$ = this._justRegistered$.asObservable();

    constructor(
        private _http: HttpClient,
        private _toastController: ToastController,
        @Inject(DOCUMENT) private _document: Document
    ) {
        this._window = this._document.defaultView as Window;
        this._loadToken();
        this._setToken(this._token);
    }

    public passwordResetRequest(email: string) {
        return this._http.post<{ id?: string }>(PASSWORD_RESET_REQUEST, {
            email,
        });
    }

    public resetPassword(requestParams: any) {

        return this._http.post(RESET_PASSWORD, {
            ...requestParams
        });
    }

    public authenticate(email: string, password: string): Observable<any> {
        return this._http.post<string>('signin', {
            email,
            password
        }).pipe(
            tap((token) => {
                if (token) {
                    this._setToken(token);
                    this._justAuthenticated$.next('authenticated');
                }
            })
        );
    }

    public authenticateByActivate(token: string) {
        if (token) {
            this._setToken(token);
            this._justAuthenticated$.next('authenticated');
        }
    }

    public register(requestParams: any) {
        return this._http.post<any>('registration', {
            ...requestParams
        }).pipe(
            tap(({ token }) => {
                if (token) {
                    this._setToken(token);
                    this._justRegistered$.next('registered');
                    this._window.location.reload();
                }
            })
        );
    }

    public logout(reload = false, clearAll = false) {
        this._clearToken(clearAll);
        if (reload) {
            this._window.location.href = this._window.location.pathname;
        }
    }

    private _setToken(token?: string | null) {
        if (token) {
            this._token = token;
            this._window.localStorage.setItem(TOKEN_KEY, token);
        }
        this._isAuthenticated$.next(!!this._token);
    }

    private _loadToken() {
        this._token = this._tokenFromUrl();
        if (!this._token) {
            this._token = this._window.localStorage.getItem(TOKEN_KEY);
        }
    }

    private _tokenFromUrl() {
        const params = this._document.location.search;
        const urlParams = new URLSearchParams(params);

        return urlParams.get('auth');
    }

    private _clearToken(clearAll = false) {
        this._token = null;
        if (clearAll) {
            // this._window.localStorage.clear();
            this._window.localStorage.removeItem(TOKEN_KEY);

        } else {
            this._window.localStorage.removeItem(TOKEN_KEY);
        }
    }

}
