import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';

import { RouterModule } from '@angular/router';

import { SchoolsListComponent } from './components/schools-list/schools-list.component';
import { JobPickerModule } from '../job-picker';
import { AutocompletePickerModule } from '../autocomplete-picker';
import { EditSchoolModalComponent } from './components/edit-school-modal/edit-school-modal.component';
import { EditSchoolFormComponent } from './components/edit-school-form/edit-school-form.component';
import { JoinPipe } from '@athlete-x/shared/pipes/join.pipe';
import { ColumnPipe } from '@athlete-x/shared/pipes/column.pipe';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        JobPickerModule,
        AutocompletePickerModule,
        JoinPipe,
        ColumnPipe
    ],
    declarations: [
        SchoolsListComponent,
        EditSchoolModalComponent,
        EditSchoolFormComponent
    ],
    exports: [
        SchoolsListComponent,
        EditSchoolModalComponent,
        EditSchoolFormComponent
    ]
})
export class SchoolsListModule {}
