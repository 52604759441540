export function MembershipYears(yearsNumber?: number, year?: number, revert = false): Array<number> {
    const startYear = year ? +year : new Date().getFullYear();
    const yearsArray: Array<number> = [];

    if (yearsNumber && yearsNumber < 0) {
        for (let x = startYear; x >= startYear + yearsNumber; x--) {
            yearsArray.push(x);
        }
    } else if (yearsNumber && yearsNumber > 0) {
        for (let x = startYear + yearsNumber; x >= startYear; x--) {
            yearsArray.push(x);
        }
    } else {
        for (let x = startYear + 5; x > startYear - 100; x--) {
            yearsArray.push(x);
        }
    }

    if (revert) {
        yearsArray.reverse();
    }

    return yearsArray;
}
