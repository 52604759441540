export const generalDomains = [
    'https://strya.co',
    'https://demo.strya.co',
    'https://staging.strya.co',
    'https://athletex-staging.firebaseapp.com',
    'https://athletex-staging.web.app',
    'http://localhost:4200',
    'http://192.168.88.253:4200',
    'http://192.168.88.250:4200'
];

