import { Injectable } from '@angular/core';
import { map, ReplaySubject, startWith } from 'rxjs';
import { User, WorkPosition } from '@athlete-x/definitions';
import { getCurrentCompany } from '@athlete-x/shared/classes/CompanyHelper';

@Injectable({
    providedIn: 'root'
})
export class UserStore {
    private _user!: User;
    private _user$ = new ReplaySubject<User>(1);

    public user$ = this._user$.asObservable()
        .pipe(map((user: User) => {
            const sortedWorkPositions = this._sortWorkPosition(user.work_positions);
            return {
                ...user,
                work_positions: sortedWorkPositions,
            };
        }));

    public isLoaded$ = this.user$.pipe(
        startWith(false),
        map((value) => !!value)
    );

    public id$ = this.user$.pipe(map((user) => user.id));
    public get id() { return this._user?.id; }

    public workPositions$ = this.user$
        .pipe(map((user) => user?.work_positions));

    public currentWorkPosition$ = this.workPositions$.pipe(map(getCurrentCompany));

    // public get firstName() { return this._user?.first_name; }
    // public get lastName() { return this._user?.last_name; }

    public setUser(user: User) {
        this._user = user;
        this._user$.next(this._user);

        console.log('User data', user);
        // console.log('User data', JSON.stringify(user));
    }

    private _sortWorkPosition(workPositions: Array<WorkPosition>) {
        if (!workPositions?.length) {
            return [];
        }

        const currentCompany: Array<WorkPosition> = [];
        const pastCompanies: Array<WorkPosition> = [];

        workPositions.map((e: WorkPosition) => {
            if (e.currently_active) {
                currentCompany.push(e);
            } else {
                pastCompanies.push(e);
            }
        });

        return [
            ...currentCompany,
            ...pastCompanies
        ];
    }
}
