import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { UpdateProfileModule } from '@athlete-x/forms/update-profile/update-profile.module';
import { SharedModule } from '@athlete-x/shared';

import { SidebarTeamsListComponent } from './components/sidebar-teams-list/sidebar-teams-list.component';
import { SidebarTeamLinksComponent } from './components/sidebar-team-links/sidebar-team-links.component';
import { SidebarMessageItemComponent } from './components/sidebar-message-item/sidebar-message-item.component';
import { DirectoryTypeModalComponent } from './components/directory-type-modal/directory-type-modal.component';
import { IfDemoDirective } from '@athlete-x/shared/directives/if-demo.directive';
import { IfProdDirective } from '@athlete-x/shared/directives/if-prod.directive';
import { UserAvatarModule } from '../user-avatar';
import { GroupTeamsPipe } from '@athlete-x/shared/pipes/group-teams.pipe';


@NgModule({
    declarations: [
        SidebarTeamsListComponent,
        SidebarTeamLinksComponent,
        SidebarMessageItemComponent,
        DirectoryTypeModalComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UpdateProfileModule,
        SharedModule,
        IfDemoDirective,
        IfProdDirective,
        UserAvatarModule,
        GroupTeamsPipe
    ],
    exports: [
        SidebarTeamsListComponent,
        SidebarTeamLinksComponent,
        SidebarMessageItemComponent,
        DirectoryTypeModalComponent
    ]
})
export class SidebarModule {
}
