import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, switchMap, take, tap } from 'rxjs';
import { ENDPOINTS, Team } from '@athlete-x/definitions';
import { TeamStore } from '@athlete-x/stores';

@Injectable({
    providedIn: 'root'
})
export class TeamDetailsService {
    constructor(
        private _http: HttpClient,
        private _teamStore: TeamStore
    ) {
    }

    load(): Observable<Team | boolean> {
        return this._teamStore.hasTeamToLoad$
            .pipe(
                take(1),
                switchMap((hasTeamToLoad) => {
                    if (hasTeamToLoad === false) {
                        return of(false);
                    }

                    return this._teamStore.isLoaded$
                        .pipe(
                            take(1),
                            switchMap((team) => {
                                if (!team) {
                                    return this._load();
                                }

                                return this._teamStore.team$
                                    .pipe(take(1));
                            })
                        );
                })
            );
    }

    reload() {
        return this._load()
            .pipe(
                tap((team) => {
                    this._teamStore.setTeam(team);
                })
            );
    }

    _load() {
        return this._http.get<Team>(ENDPOINTS.teamDetails, {
            params: { team_id: this._teamStore.id }
        });
    }

    public uploadBanner(base64: string, teamId?: string | number) {
        const params = {
            banner_base64: base64,
            filename: 'team_banner'
        };

        return this._http.patch(ENDPOINTS.teamBannerUpload, {
            ...params,
            team_id: teamId || this._teamStore.id
        }).pipe(
            switchMap(() =>  this.reload())
        );
    }

    public uploadLogo(base64: string, teamId?: string | number) {
        const params = {
            logo_base64: base64,
            filename: 'team_logo',
            team_id: teamId || this._teamStore.id
        };

        return this._http.patch(ENDPOINTS.teamLogoUpload, { ...params });
    }

    public updateTeamLink(params: any) {
        return this._http.post(ENDPOINTS.teamLinkUpdate, {
            ...params,
            team_id: this._teamStore.id
        }).pipe(
            tap(() => this._teamStore.reloadLinks())
        );
    }

    public createTeamLink(params: any) {
        return this._http.post(ENDPOINTS.teamLinkCreate, {
            ...params,
            team_id: this._teamStore.id
        }).pipe(
            tap(() => this._teamStore.reloadLinks())
        );
    }

    public deleteTeamLink(id: any) {
        return this._http.post(ENDPOINTS.teamLinkDelete, { id }).pipe(
            tap(() => this._teamStore.reloadLinks())
        );
    }
}
