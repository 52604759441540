import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { StepIndicatorComponent } from './components/step-indicator.component';

@NgModule({
    declarations: [
        StepIndicatorComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
    ],
    exports: [
        StepIndicatorComponent
    ]
})
export class ProfileCompleteAddonsModule {
}
