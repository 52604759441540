import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, of } from 'rxjs';
import { TeamStore } from '@athlete-x/stores';
import { Team } from '@athlete-x/definitions';
import { isGeneralDomain } from '@athlete-x/shared/classes/DomainHelper';

@Injectable({
    providedIn: 'root'
})
export class TeamService {
    private _team!: Team;
    public get slug() {
        return this._team?.slug || '';
    }

    constructor(
        private _http: HttpClient,
        private _teamStore: TeamStore
    ) {
    }

    public load(slug?: string) {
        return this._loadTeam(slug)
            .pipe(
                map((team) => team?.id ? team : null),
                map((teamData) => {
                    this._teamStore.setTeam(teamData);
                    this._team = teamData;

                    return !!teamData;
                }),
            );
    }

    private _loadTeam(slug?: string) {
        let finder: 'slug' | 'custom_domain';
        let value;

        const generalDomain = isGeneralDomain();
        if (generalDomain) {
            if (!slug) {
                return of({} as any);
            }

            value = slug.replace('/', '');
            finder = 'slug';

        } else {
            value = globalThis?.location?.origin;
            finder = 'custom_domain';
        }

        return this._http.get<Team>('teams/public_data', {
            params: {
                finder,
                value
            }
        });
    }
}
