import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';

import { FormBuilder } from '@angular/forms';
import { UserService } from '@athlete-x/shared';
import { User } from '@athlete-x/definitions';
import { switchMap } from 'rxjs';

@Component({
    selector: 'atx-directory-type-toggle',
    templateUrl: './directory-type-toggle.component.html',
    styleUrls: ['./directory-type-toggle.component.scss'],
})
export class DirectoryTypeToggleComponent implements OnInit {
    @Input() set user(value: User | null) {
        if (value) {
            this.form
                .patchValue(
                    {
                        global: value.global
                    },
                    {
                        onlySelf: true,
                        emitEvent: false
                    }
                );
        }
    }

    @Input() autoOn = false;

    @Input() editing = false;
    @Input() processing = false;

    @Output() valueChanges = new EventEmitter();

    public form = this._formBuilder.group({
        global: [false]
    });

    constructor(
        private _formBuilder: FormBuilder,
        private _userService: UserService,
    ) {
    }

    ngOnInit() {
        this.form.valueChanges
            .pipe(
                switchMap(value => this._userService.updateProfile({ ...value })),
                switchMap(() => this._userService.reload())
            )
            .subscribe(() => {
                // this._utilities.presentToast('Global access settings updated ');
                this.valueChanges.emit({
                    ...this.form.value
                });
        });

        if (this.autoOn) {
            this.form.patchValue({global: true});
        }
    }
}
