import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from './auth.service';
import { catchError, throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private _auth: AuthService) {
    }

    intercept(req: HttpRequest<never>, next: HttpHandler) {
        if (req.method === 'JSONP') {
            return next.handle(req);
        }

        if (this._auth.token) {
            const authReq = req.clone({
                headers: req.headers
                    .append('token', this._auth.token.toString())
            });

            return next.handle(authReq)
                .pipe(
                    catchError((error) => {
                        if (error.status === 401) {
                            // alert('Please sign in again');
                            this._auth.logout(true, true);
                        }

                        return throwError(error);
                    })
                );
        }

        return next.handle(req);
    }
}
