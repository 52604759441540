import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { TeamStore } from '@athlete-x/stores';
import { Subscription } from 'rxjs';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ifUserIs]'
})
export class IfUserIsDirective implements OnDestroy {
    /**
     * Available types:
     * - player
     * - alumni
     * - parent
     * - staff
     * - active
     * - pending
     * Roles can be negated with '!'
     */
    @Input() set ifUserIs(value: Array<string>) {
        this._types = value;
        this._update();
    }

    protected _types: Array<string> = [];

    protected _sub!: Subscription;
    protected _isVisible = false;
    constructor(
        protected template: TemplateRef<any>,
        protected entry: ViewContainerRef,
        protected _teamStore: TeamStore
    ) {
        this._update();
        this._sub = this._teamStore.team$.subscribe(() => {
            this._update();
        });
    }

    ngOnDestroy(): void {
        if (this._sub) {
            this._sub.unsubscribe();
        }
    }

    protected get _shouldRender() {
        let result = true;
        if (!this._types || !this._types.length) {
            return result;
        }
        // console.log('Checking: ' + this._roles.join(','));
        for (let type of this._types) {
            if (type.indexOf('!') === 0) {
                type = type.slice(1);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                result = !this._teamStore[type] && result;

            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                result = this._teamStore[type as any] && result;
            }
        }
        // console.log('Result', result);

        return result;
    }

    protected _update() {
        if (!this._shouldRender) {
            if (this._isVisible) {
                this.entry.clear();
                this._isVisible = false;
            }

        } else if (!this._isVisible) {
            this.entry.createEmbeddedView(this.template);
            this._isVisible = true;
        }
    }
}
