import { Component, EventEmitter, Output, Input } from '@angular/core';

import { FormBuilder } from '@angular/forms';
import { UserService } from '@athlete-x/shared';
import { User } from '@athlete-x/definitions';
import { switchMap } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { VisibilitySettingsModalComponent } from '../visibility-settings-modal/visibility-settings-modal.component';

export const VisibilitySettingsTitle: {
    [key: string]: {
        title: string,
        subtitle: string
    }
} = {
    'always_show': {
        title: 'All',
        subtitle: 'Members in all of my networks + Global'
    },
    'show_for_school_teams_only': {
        title: 'My School Networks',
        subtitle: 'Members in my College + High School networks only'
    },
    'show_for_teams_only': {
        title: 'My School/Group Networks',
        subtitle: 'Members in my College + High School + Group networks only'
    },
    'always_hide': {
        title: 'Only Me',
        subtitle: 'No one can see my email address, except admins'
    }
};

@Component({
    selector: 'atx-privacy-settings',
    templateUrl: './privacy-settings.component.html',
    styleUrls: ['./privacy-settings.component.scss'],
})
export class PrivacySettingsComponent {
    @Input() set user(value: User | null) {
        if (value) {
            this.form
                .patchValue(
                    {
                        phone_visibility_setting: value.phone_visibility_setting,
                        email_visibility_setting: value.email_visibility_setting
                    },
                    {
                        onlySelf: true,
                        emitEvent: false
                    }
                );
        }
    }

    @Input() editing = false;
    @Input() processing = false;

    @Input() modal = false;

    @Output() privacySettingsChange = new EventEmitter();

    public visibilitySettings = VisibilitySettingsTitle;

    public form = this._formBuilder.group({
        email_visibility_setting: [''],
        phone_visibility_setting: ['']
    });

    public get emailVisibilitySetting() { return this.form.value['email_visibility_setting'] || 'always_show'; }
    public get phoneVisibilitySetting() { return this.form.value['phone_visibility_setting'] || 'always_show'; }

    constructor(
        private _formBuilder: FormBuilder,
        private _userService: UserService,
        private _modalCtrl: ModalController
    ) {
    }

    public openPhoneVisibilitySettingsModal() {
        this._openVisibilitySettingsModal(this.phoneVisibilitySetting, 'phone')
            .then(({ data, role }) => {
                if (role === 'confirm') {
                    this.form.patchValue({ phone_visibility_setting: data });
                    this._updateUserData();
                }
            });
    }

    public openEmailVisibilitySettingsModal() {
        this._openVisibilitySettingsModal(this.emailVisibilitySetting, 'email')
            .then(({ data, role }) => {
                if (role === 'confirm') {
                    this.form.patchValue({ email_visibility_setting: data });
                    this._updateUserData();
                }
            });
    }

    private _updateUserData() {
        return this._userService.updateProfile({ ...this.form.value })
            .pipe(
                switchMap(() => this._userService.reload())
            )
            .subscribe();
    }

    private _openVisibilitySettingsModal(visibilitySetting: string, inputType: string) {
        return this._modalCtrl
            .create({
                component: VisibilitySettingsModalComponent,
                componentProps: {
                    visibilitySetting,
                    inputType
                }
            })
            .then(modal => {
                modal.present();
                return modal.onDidDismiss();
            });
    }

}
