import { Injectable } from '@angular/core';
import {
    distinctUntilChanged,
    map, filter,
    Observable, repeat,
    ReplaySubject,
    shareReplay,
    startWith,
    switchMap, Subject, take,
} from 'rxjs';
import {
    ENDPOINTS,
    Industry,
    Team,
    TeamAdminData,
    TeamFiltersFills, TeamLink,
    TeamType,
    User,
    UserType
} from '@athlete-x/definitions';
import { TeamHelper } from '@athlete-x/shared/classes/TeamHelper';
import { environment } from '@athlete-x/env/environment';
import { HttpClient } from '@angular/common/http';

const STAGING_DEMO_TEAM_DATA: { [key: number]: any } = {
    1: {
        slogan: 'Connected for Lifez',
        color: '#002D61' // Team name color
    },
    6: {
        slogan: 'Connected for Life',
        color: '#002D61' // Team name color
    },
    9: {
        slogan: 'Connected for Life',
        color: '#002D61' // Team name color
    },
    20: {
        slogan: 'COURAGE, GRIT, RESOLVE: WE ARE SHU',
        color: '#CE1041' // Team name color
    },
};

const PRODUCTION_TEAM_DATA: { [key: number]: any } = {
    3: {
        slogan: 'Connected for Life',
        color: '#002D61' // Team name color
    },
    7: {
        slogan: 'Networked For Life',
        color: '#222D4A' // Team name color
    },
    8: {
        slogan: 'Networked For Life',
        color: '#222D4A' // Team name color
    },
    9: {
        slogan: 'COURAGE, GRIT, RESOLVE : WE ARE SHU',
        color: '#CE1041' // Team name color
    },
    10: {
        slogan: 'Networked For Life',
        color: '#222D4A' // Team name color
    },
    12: {
        slogan: 'United In Blue',
        color: '#0148B6' // Team name color
    },
    13: {
        slogan: 'Fly Hawks Fly!',
        color: '#002469' // Team name color
    },
};

const ADDITIONAL_TEAM_DATA = environment.production ? PRODUCTION_TEAM_DATA : STAGING_DEMO_TEAM_DATA;

@Injectable({
    providedIn: 'root'
})
export class TeamStore {
    private _team!: Team;
    private _team$ = new ReplaySubject<Team>(1);

    public team$ = this._team$.asObservable();
    public admin$ = this.team$.pipe(map((data) => data.admin));
    public hasTeamToLoad$ = this.team$.pipe(map((value) => !!value));
    public isLoaded$ = this.team$.pipe(
        startWith(false),
        map((value) => !!value)
    );

    public teamId$: Observable<number> = this.team$.pipe(
        map((team) => team.id),
        distinctUntilChanged()
    );
    public filters$ = this.teamId$
        .pipe(
            switchMap((teamId) => this._getFilters(teamId)),
            shareReplay(1)
        );

    private _reloadLinks$ = new Subject<void>();
    public links$ = this.team$
        .pipe(
            filter((team) => team.status === 'active'),
            switchMap(() => this.teamId$),
            switchMap((teamId) => this._http.get<Array<TeamLink>>(ENDPOINTS.teamLinks, { params: { team_id: teamId} })
                .pipe(repeat({ delay: () => this._reloadLinks$ }))),
            startWith([]),
            shareReplay(1)
        );

    public teamCategory$ = this._team$.pipe(map(this._getTeamCategory));

    private _reloadAdminData$ = new Subject<void>();
    public adminData$ = this.admin$
        .pipe(
            filter((admin) => admin),
            switchMap(() => this.teamId$),
            switchMap((id) => this._http.get<TeamAdminData>(ENDPOINTS.teamAdminData,  {
                params: { team_id: id }
            }).pipe(repeat({ delay: () => this._reloadAdminData$ }))),
            shareReplay(1),
        );
    public teamPlan$ = this.adminData$.pipe(map((data) => data.payment_plan), startWith(''));

    public get id() { return this._team?.id; }

    public get type() { return this._team?.user_type; }
    public get admin() { return this._team?.admin; }
    public get player() { return this._team?.user_type === UserType.PLAYER; }
    public get parent() { return this._team?.user_type === UserType.PARENT; }
    public get alumni() { return this._team?.user_type === UserType.ALUMNI; }
    public get staff() { return this._team?.user_type === UserType.STAFF; }
    public get friend() { return this._team?.user_type === UserType.FRIEND; }
    public get pending() { return this._team?.status === 'pending'; }
    public get active() { return this._team?.status === 'active'; }
    public get teamType(): TeamType { return this._team.teamType; }
    public get teamCategory() { return this._team.team_category; }

    constructor(
        private _http: HttpClient
    ) {
    }

    public setTeam(team: Team) {
        if (ADDITIONAL_TEAM_DATA[team?.id] && !team['slogan']) {
            this._team = {
                ...team,
                ...ADDITIONAL_TEAM_DATA[team.id]
            };

        } else {
            this._team = team;
        }

        this._team$.next(this._team);
    }

    public fillFromProfile(profile: User) {
        const team = profile.teams.find((t) => t.id === this.id);
        if (team) {
            this.fillTeamData(team);
        }
    }

    public fillTeamData(team: Team) {
        console.log('Current Team', team);
        console.log('Signed in as: %c' + team?.user_type, 'color: red',);

        this._team = {
            ...this._team,
            ...team
        };

        this._team.teamType = TeamHelper.teamType(this._team);

        this._team$.next(this._team);
    }

    private _getTeamCategory(team: Team): 'school' | 'group' {
        if (team.team_category) {
            return team.team_category;
        }

        const type = TeamHelper.teamType(team);

        return type === TeamType.GROUP ? 'group' : 'school';
    }

    public _getFilters(team_id: number) {
        const params = {
            team_id
        };

        return this._http
            .get<TeamFiltersFills>('teams/filter_keys', { params })
            .pipe(map(({ grad_years, industries, locations, ...restParams }) => ({
                ...restParams,
                grad_years: this._parseGradYears(grad_years),
                industries: this._parseIndustriesNames(industries),
                locations: this._parseLocations(locations)
            })));
    }

    public reloadLinks() { this._reloadLinks$.next(); }
    public reloadAdminData() { this._reloadAdminData$.next(); }

    public reloadTeam() {
        this.teamId$
            .pipe(
                take(1),
                switchMap((team_id) => this._http.get<Team>(ENDPOINTS.teamDetails, { params: { team_id } }))
            ).subscribe((team) => this.fillTeamData(team));
    }

    private _parseGradYears(gradYears: Array<string>) {
        if (!gradYears?.length) {
            return [];
        }
        return gradYears
            .filter((e: string) => e)
            .sort()
            .reverse();
    }

    private _parseIndustriesNames(industries: Array<Industry>) {
        if (!industries?.length) {
            return [];
        }
        return industries
            .filter((e: Industry) => e)
            .sort((a: Industry, b: Industry) => {
                return a.name.localeCompare(b.name);
            });
    }

    private _parseLocations(locations: Array<string>) {
        if (!locations?.length) {
            return [];
        }
        locations = locations
            .filter((e: string) => {
                if (!e || e?.length <= 2) {
                    return false;
                }

                const locationsParts = e
                    .split(',')
                    .filter(e => e);

                if (locationsParts.length < 2) {
                    return false;
                }

                return true;
            })
            .sort((a, b) => {
                return a.localeCompare(b);
            });

        return Array.from(new Set(locations));
    }

}
