<form [formGroup]="form" autocomplete="off" class="form-container" [class.modal]="modal">
    <ion-grid class="ion-no-padding">
        <ion-row>
            <ion-col size="12">
                <h3>Who can see my email address?</h3>
                <ion-item class="input-container" lines="none" (click)="openEmailVisibilitySettingsModal()" expand="block">
                    <ion-label class="input-title">
                        <ion-text>Email Address Access</ion-text>
                    </ion-label>

                    <ion-label class="input-button">
                        <ion-text class="d-flex a-i-center j-c-end">
                            {{visibilitySettings[emailVisibilitySetting].title}}
                            <ion-icon name="caret-down-outline" class="ml-1"></ion-icon>
                        </ion-text>
                    </ion-label>
                </ion-item>
            </ion-col>
            <ion-col size="12">
                <h3>Who can see my phone number?</h3>
                <ion-item class="input-container" lines="none" (click)="openPhoneVisibilitySettingsModal()" expand="block">
                    <ion-label class="input-title">
                        <ion-text>Phone Number Access</ion-text>
                    </ion-label>

                    <ion-label class="input-button">
                        <ion-text class="d-flex a-i-center j-c-end">
                            {{visibilitySettings[phoneVisibilitySetting].title}}
                            <ion-icon name="caret-down-outline" class="ml-1"></ion-icon>
                        </ion-text>
                    </ion-label>
                </ion-item>
            </ion-col>
        </ion-row>
    </ion-grid>
</form>
