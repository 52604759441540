import {
    CanMatchFn,
    Router, UrlTree,
} from '@angular/router';
import { inject, InjectionToken } from '@angular/core';
import { map, Observable, take } from 'rxjs';
import { AuthService } from './auth.service';

export const BASE_AUTH_PATH = new InjectionToken<string>('Base auth path');

export const IsAuthenticated: CanMatchFn = (): Observable<boolean | UrlTree> | boolean | UrlTree => {
    const auth = inject(AuthService);
    const baseAuthPath = inject(BASE_AUTH_PATH);
    const router = inject(Router);

    // if (!auth.isAuthenticated) {
    //     return router.parseUrl(baseAuthPath);
    // }
    //
    // return true;

    return auth.isAuthenticated$
        .pipe(
            take(1),
            map((isAuthenticated) => {
                if (!isAuthenticated) {
                    return router.parseUrl(baseAuthPath);
                }

                return true;
            }),
        );
};
