import { Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PRODUCTION } from '@athlete-x/definitions';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ifProd]',
    standalone: true
})
export class IfProdDirective {
    private _showIfProd = true;
    protected _isVisible = false;

    @Input() set ifProd(show: boolean) {
        this._showIfProd = show;
        this._update();
    }

    constructor(
        protected template: TemplateRef<any>,
        protected entry: ViewContainerRef,
        @Inject(PRODUCTION) protected _isProd: boolean
    ) {
        this._update();
    }


    protected get _shouldRender() {
        return this._isProd ? this._showIfProd : true;
    }

    protected _update() {
        if (!this._shouldRender) {
            if (this._isVisible) {
                this.entry.clear();
                this._isVisible = false;
            }

        } else if (!this._isVisible) {
            this.entry.createEmbeddedView(this.template);
            this._isVisible = true;
        }
    }
}
