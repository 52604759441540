import { WorkPosition } from '@athlete-x/definitions';

export function getCurrentCompany(workPostions: Array<WorkPosition>) {

    if (!workPostions.length) {
        return null;
    }

    const currentlyActive = workPostions.filter(wp => wp.currently_active);

    if (currentlyActive.length === 1) {
        return currentlyActive[0];

    } else if (currentlyActive.length > 1) {
        return getLastByDate(currentlyActive, 'start_date') ;

    } else if (workPostions.length === 1) {
        return workPostions[0];

    } else {
        return getLastByDate(workPostions, 'end_date') ;

    }
}

function getLastByDate(workPostions: Array<WorkPosition>, field: 'start_date' | 'end_date') {
    let temp: WorkPosition = workPostions[0];
    let tempDateValue = (new Date(temp[field])).getTime();

    workPostions
        .forEach((wp: WorkPosition) => {
            const currentDateValue = (new Date (wp[field])).getTime();
            if (currentDateValue > tempDateValue) {
                temp = wp;
                tempDateValue = currentDateValue;
            }
        });

    return temp;
}
