import { AfterViewInit, Component } from '@angular/core';
import { Message } from '@athlete-x/definitions';

import { MessageBoardService } from '@athlete-x/features/message-board/services/message-board.service';
import { Observable, map } from 'rxjs';

@Component({
    selector: 'atx-sidebar-message-item',
    templateUrl: './sidebar-message-item.component.html',
    styleUrls: ['./sidebar-message-item.component.scss']
})
export class SidebarMessageItemComponent implements AfterViewInit {
    public items$: Observable<Array<Message>> = this._messageBoardService.data$
        .pipe(map(r => r.length >= 1 ? r.slice(0, 1) : r));

    constructor(
        private _messageBoardService: MessageBoardService,
    ) { }

    ngAfterViewInit() {
        this._loadMessages();
    }

    public getId(index: number, el: Message) {
        return el.id;
    }

    private _loadMessages() {
        this._messageBoardService
            .load()
            .subscribe();
    }

    public postNewMessage() {
        this._messageBoardService.postNewMessage();
    }
}
