<ion-header>
    <ion-toolbar>
        <ion-title>Global Directory Access</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="athletex-theme">

            <ion-grid style="padding: 1rem;">

                <ion-row class="ion-justify-content-center form-section">

                    <ion-col size="12" class="ion-no-padding">
                        <p class="m-1">
                            Our Global Directory enhances the value of your network, i.e. connect with members from other teams at your school. You can change this at any time.
                        </p>
                    </ion-col>

                    <ng-container *ngIf="user$ | async as user">

                        <ion-col size="12">
                            <atx-directory-type-toggle
                                [user]="user"
                                (valueChanges)="onDirectoryTypeChange($event)">
                            </atx-directory-type-toggle>
                        </ion-col>

                        <ion-col size="12">
                            <h3 class="m-1">Who can see me?</h3>
                            <atx-directory-global-visiblity [user]="user">
                            </atx-directory-global-visiblity>
                        </ion-col>

                        <ion-col size="12">
                            <atx-privacy-settings
                                [user]="user"
                                [modal]="true">
                            </atx-privacy-settings>
                        </ion-col>

                    </ng-container>

                </ion-row>
            </ion-grid>

    </div>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button size="block" (click)="confirm()" color="primary" fill="outline" shape="round">
                <ion-label style="text-transform: capitalize; font-weight: 400;">Ok</ion-label>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>

