<ion-list lines="none" class="sidebar-menu-item athletex-theme">

    <ion-list-header class="ion-no-padding">
        <div class="header-container">
            <span>Links</span>

            <ion-buttons *ifAdmin="true">
                <ion-button (click)="openTeamLinks()" class="side-menu-button">
                    <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </div>
    </ion-list-header>

    <ion-item class="ion-no-padding" >
        <div *ngIf="links" >
            <ion-chip *ngFor="let link of links" (click)="goToLink(link.url)">
                <ng-container *ngIf="link.url | linkToIconName as iconName">
                    <ion-icon [name]="iconName" class="ion-no-margin"></ion-icon>
                    <ion-label *ngIf="iconName === 'earth-outline'">
                        {{link.name}}
                    </ion-label>
                </ng-container>
            </ion-chip>
        </div>
    </ion-item>

</ion-list>
