import { Component, Input, ViewChild } from '@angular/core';
import { AspectRatioTypes, CompresionTypes } from '@athlete-x/definitions';
import { ModalController } from '@ionic/angular';
import { ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';

import { Device } from '@capacitor/device';
import { DeviceInfo } from '@capacitor/device';

@Component({
    selector: 'atx-image-cropper-modal',
    templateUrl: './image-cropper-modal.component.html',
    styleUrls: ['./image-cropper-modal.component.scss']
})
export class ImageCropperModalComponent {
    public image!: string;
    public aspectRatio = 1;
    public maintainAspectRatio = true;
    @Input() compression = CompresionTypes.PNG;

    @Input() set base64(value: string) {
        this.image = `data:image/${this.compression};base64,${value}`;
    }

    @Input() set aspectRatioType(value: AspectRatioTypes) {
        if (value === AspectRatioTypes.SQUARE) {
            this.aspectRatio = 1;
        } else if (value === AspectRatioTypes.RECTANGLE) {
            this.aspectRatio = 2.5;
        }else if (value === AspectRatioTypes.WIDE) {
            this.aspectRatio = 6;
        } else if (value === AspectRatioTypes.ORIGINAL) {
            this.maintainAspectRatio = false;
        }
    }

    @ViewChild('cropper') cropper!: ImageCropperComponent;
    public transform: ImageTransform = {};
    public isMobile = true;

    constructor(
        private _modalController: ModalController
    ) {
        this._getDeviceInfo();
    }

    public dismissModal(base64?: string | undefined | null) {
        base64 = base64 ? base64.replace(`data:image/${this.compression};base64,`, '') : '';

        this._modalController.dismiss({
            base64
        });
    }

    private _getDeviceInfo() {
        return Device.getInfo()
            .then((deviceInfo: DeviceInfo) => {
                const { platform } = deviceInfo;
                this.isMobile = !(platform === 'web');
            });
    }

    //   Manually trigger the crop
    public cropImage() {
        const cropeperImage = this.cropper.crop();
        const base64 = cropeperImage?.base64;

        this.dismissModal(base64);
    }

    //   Edit the image
    public rotate() {
        const newValue = ((this.transform.rotate ?? 0) + 90) % 360;

        this.transform = {
            ...this.transform,
            rotate: newValue,
        };
    }

    public flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH,
        };
    }

    public flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV,
        };
    }
}
