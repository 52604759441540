import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

export const VisibilitySettingsTitle: {
    [key: string]: {
        title: string,
        subtitle: string
    }
} = {
    'always_show': {
        title: 'All',
        subtitle: 'Members in all of my networks + Global'
    },
    'show_for_school_teams_only': {
        title: 'My School Networks',
        subtitle: 'Members in my College + High School networks only'
    },
    'show_for_teams_only': {
        title: 'My School/Group Networks',
        subtitle: 'Members in my College + High School + Group networks only'
    },
    'always_hide': {
        title: 'Only Me',
        subtitle: 'No one can see my email address, except admins'
    }
};

@Component({
    selector: 'atx-visibility-settings-modal',
    templateUrl: './visibility-settings-modal.component.html',
    styleUrls: ['./visibility-settings-modal.component.scss'],
})
export class VisibilitySettingsModalComponent {
    @Input() visibilitySetting!: string;
    @Input() inputType!: string;

    public visibilitySettings = VisibilitySettingsTitle;

    constructor(
        private _modalController: ModalController
    ) {
    }

    public confirm() {
        this._modalController.dismiss(this.visibilitySetting, 'confirm');
    }

    public onVisibilityChange(value: any) {
        this.visibilitySetting = value;
    }

}
