import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of, ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AutocompletedPlace } from '@athlete-x/definitions';

export const GOOGLE_MAPS_KEY = new InjectionToken<string>('Google maps key');

@Injectable()
export class GoogleMapsService {
    private _ready$ = new ReplaySubject();

    constructor(
        @Inject(GOOGLE_MAPS_KEY) private _key: string,
        private _http: HttpClient
    ) {
        const URL = 'https://maps.googleapis.com/maps/api/js';
        const key = '?key=' + this._key;
        const libraries = '&libraries=' + ['places'].join(',');
        const opts = '&language=en';
        // const opts = '&language=en&region=us';

        this._http.jsonp(URL + key + libraries + opts, 'callback')
            .pipe(
                map(() => true),
                catchError((error) => {
                    console.log(error);

                    return of(false);
                }),
            ).subscribe((loaded) => {
                if (loaded) {
                    this._ready$.next(true);
                }
            });

    }

    public placesAutocomplete(input: any): Observable<AutocompletedPlace> {
        return this._ready$
            .pipe(
                switchMap(() => new Observable<AutocompletedPlace>((observer) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const autocomplete = new google.maps.places.Autocomplete(input, {
                        // componentRestrictions: { country: ['us'] },
                        types: ['locality', 'administrative_area_level_1'],

                    });

                    autocomplete.addListener('place_changed', () => {
                        const plc = autocomplete.getPlace();

                        //  zip_code, state,
                        const place = {
                            id: plc.place_id,
                            city: this._getComponentName(plc, 'locality'),
                            state: this._getComponentName(plc, 'administrative_area_level_1', 'short_name'),
                        };

                        observer.next(place);
                    });
                }))
            );
    }

    private _getComponentName(place: any, param: string | Array<string>, type: 'long_name' | 'short_name' = 'long_name'): string | undefined {
        if (typeof (param) !== 'string') {
            do {
                const field = param.shift() as string;
                const result = this._getComponentName(place, field, type);
                if (result) {
                    return result;
                    break;
                }

            } while (param.length);
        }

        const component = place.address_components.find((c: any) => (c.types.indexOf(param) !== -1));
        if (component) {
            return component[type];

        } else {
            console.warn(`Place has no: '${param}' set`);
            return undefined;
        }
    }
}
