import { Pipe, PipeTransform } from '@angular/core';
const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const YESTERDAY = 36 * HOUR;

@Pipe({
    name: 'timestampParse'
})
export class TimestampParsePipe implements PipeTransform {

    transform(value: string, now = null): unknown {
        const pastTimestamp = (new Date(value)).getTime() /1000;
        const presentTimestap = now || Date.now() / 1000;

        let timeDiff = presentTimestap - pastTimestamp;

        if (timeDiff <= MINUTE) {
            return 'Just Now';

        } else if (timeDiff > MINUTE && timeDiff < HOUR) {

            timeDiff = Math.trunc(timeDiff / MINUTE);
            return `${timeDiff}m`;

        } else if (timeDiff > HOUR && timeDiff < DAY) {

            timeDiff = Math.trunc(timeDiff / HOUR);
            return `${timeDiff}h`;

        } else if (timeDiff > DAY && timeDiff < YESTERDAY) {
            return 'Yesterday';
        }

        return this._parseToDate(pastTimestamp);
    }

    private _parseToDate(timestamp: number) {
        const date = new Date(timestamp * 1000); // convert to milliseconds
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear() - 2000;

        return  month + '/' + day + '/' + year.toString();
    }

}
