import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PositionPickerComponent } from './components/position-picker/position-picker';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { IonicModule } from '@ionic/angular';
import { PositionService } from './services/position.service';
import { JoinPipe } from '@athlete-x/shared/pipes/join.pipe';
import { SharedModule } from '@athlete-x/shared';

@NgModule({
    declarations: [
        PositionPickerComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AutocompleteLibModule,
        SharedModule,
        JoinPipe
    ],
    exports: [
        PositionPickerComponent
    ],
    providers: [
        PositionService
    ]
})
export class PositionPickerModule {
}
