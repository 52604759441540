import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class MainLoaderService {
    private _hiding: Promise<void> | null = null;
    private _showing: Promise<void> | null = null;

    private _loader!: HTMLElement;

    constructor(@Inject(DOCUMENT) document: Document) {
        this._loader = document.getElementById('mainLoader') as HTMLElement;
    }

    public hide(delay = 0): Promise<void> {
        if (this._loader) {
            if (!this._hiding) {
                this._hiding = new Promise<void>((resolve) => {
                    setTimeout(() => {
                        this._loader.className = 'hiding';
                        setTimeout(() => {
                            this._loader.className = 'hidden';
                            this._hiding = null;
                            resolve();
                        }, 500);
                    }, delay);
                });
            }

            return this._hiding;
        }

        return Promise.resolve();
    }

    public show(instantly = false) {
        if (this._loader) {
            return new Promise<void>((resolve) => {
                this._loader.className = instantly ? '' : 'hiding';
                setTimeout(() => {
                    this._loader.className = '';
                    resolve();

                }, 500);
            });
        }

        return Promise.resolve();
    }
}
