
<ion-header>
    <ion-toolbar>
        <ion-title>{{inputType === 'email' ? 'Email Address Access' : 'Phone Number Access' }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

    <ion-item class="option" lines="none">
        <ion-checkbox
            [checked]="visibilitySetting === 'always_show'"
            (ionChange)="onVisibilityChange('always_show')">
            <h4>{{visibilitySettings['always_show'].title}}</h4>
            <p>{{visibilitySettings['always_show'].subtitle}}</p>
        </ion-checkbox>
    </ion-item>

    <ion-item class="option" lines="none">
        <ion-checkbox
            [checked]="visibilitySetting === 'show_for_school_teams_only'"
            (ionChange)="onVisibilityChange('show_for_school_teams_only')">
            <h4>{{visibilitySettings['show_for_school_teams_only'].title}}</h4>
            <p>{{visibilitySettings['show_for_school_teams_only'].subtitle}}</p>
        </ion-checkbox>
    </ion-item>

    <ion-item class="option" lines="none">
        <ion-checkbox
            [checked]="visibilitySetting === 'show_for_teams_only'"
            (ionChange)="onVisibilityChange('show_for_teams_only')">
            <h4>{{visibilitySettings['show_for_teams_only'].title}}</h4>
            <p>{{visibilitySettings['show_for_teams_only'].subtitle}}</p>
        </ion-checkbox>
    </ion-item>

    <ion-item class="option" lines="none">
        <ion-checkbox
            [checked]="visibilitySetting === 'always_hide'"
            (ionChange)="onVisibilityChange('always_hide')">
            <h4>{{visibilitySettings['always_hide'].title}}</h4>
            <p>{{visibilitySettings['always_hide'].subtitle}}</p>
        </ion-checkbox>
    </ion-item>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="confirm()" [strong]="true">Ok</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
