import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MessageBoardComponent } from './components/message-board/message-board.component';
import { SharedModule } from '@athlete-x/shared';

import { TeamBannerModule, UserAvatarModule } from '@athlete-x/ui';
import { PostMessageModalComponent } from './components/post-message-modal/post-message-modal.component';
import { AutosizeModule } from 'ngx-autosize';
import { MessageBoardPanelComponent } from './components/message-board-panel/message-board-panel.component';
import { ParseContentPipe } from '@athlete-x/shared/pipes/parse-content.pipe';


@NgModule({
    declarations: [
        MessageBoardComponent,
        PostMessageModalComponent,
        MessageBoardPanelComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        ScrollingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        TeamBannerModule,
        AutosizeModule,
        SharedModule,
        ParseContentPipe,
        UserAvatarModule
    ],
    exports: [
        MessageBoardComponent,
        PostMessageModalComponent,
        MessageBoardPanelComponent
    ]
})
export class MessageBoardModule { }
