import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AutocompletePickerComponent } from './components/autocomplete-picker/autocomplete-picker';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { IonicModule } from '@ionic/angular';
import { AutocompleteModalComponent } from './components/autocomplete-modal/autocomplete-modal';
import { AutocompleteService } from './services/autocomplete.service';
import { FilterAutocompletePickerComponent } from './components/filter-autocomplete-picker/filter-autocomplete-picker';
import { JoinPipe } from '@athlete-x/shared/pipes/join.pipe';

@NgModule({
    declarations: [
        AutocompletePickerComponent,
        AutocompleteModalComponent,
        FilterAutocompletePickerComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AutocompleteLibModule,
        JoinPipe
    ],
    exports: [
        AutocompletePickerComponent,
        AutocompleteModalComponent,
        FilterAutocompletePickerComponent
    ],
    providers: [
        AutocompleteService
    ]
})
export class AutocompletePickerModule {
}
