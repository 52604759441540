import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IndustriesPickersListComponent } from './industries-pickers-list';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '@athlete-x/shared';

@NgModule({
    declarations: [
        IndustriesPickersListComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ],
    exports: [
        IndustriesPickersListComponent
    ]
})
export class IndustriesPickersListModule {
}
