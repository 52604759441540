<ion-split-pane when="xl" contentId="main">
    <ion-menu [swipeGesture]="false" menuId="main-menu" contentId="main">
        <ng-container *ngIf="(vm$ | async) as vm">
            <ion-header class="nav-bar left-menu">
                <ion-toolbar class="athletex-theme main-user-data">
                    <ion-item lines="none" class="sidebar-menu-item ion-no-padding" (click)="toggleMenu()">

                        <atx-user-avatar
                            [avatar]="vm.user.avatar"
                            [routerLink]="vm.user.profile_completed ? ['/user/' + vm.user.id] : undefined"
                            slot="start">
                        </atx-user-avatar>

                        <ion-label>
                            <ion-text class="d-block" [routerLink]="vm.user.profile_completed ? ['/user/' + vm.user.id] : undefined"
                                title="Show public profile">
                                <h4 class="title">
                                    {{ vm.user.first_name }} {{vm.user.last_name}}
                                </h4>

                                <h4 class="subtitle">
                                    {{ vm.currentCompany?.company_name }}
                                    <ng-container *ngIf="vm.jobsTitles?.length"> | </ng-container>
                                    <ng-container *ngIf="vm.jobsTitles?.length === 1">
                                        {{vm.jobsTitles[0]}}
                                    </ng-container>
                                    <ng-container *ngIf="vm.jobsTitles?.length > 1">
                                        {{vm.jobsTitles[0] + '...'}}
                                    </ng-container>
                                </h4>

                                <h4 class="description">
                                    {{ vm.user.address }}
                                </h4>
                            </ion-text>
                        </ion-label>

                        <ion-buttons *ngIf="vm.user.profile_completed" slot="end">
                            <ion-button [routerLink]="['/edit-profile']" title="Edit profile">
                                <span>Edit</span>
                            </ion-button>
                        </ion-buttons>

                    </ion-item>

                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-no-padding">
                <ng-scrollbar [visibility]="'hover'">
                    <div class="sidebar-content-container">
                        <atx-sidebar-teams-list
                            [user]="vm.user"
                            [currentTeam]="vm.team"
                            [isGlobal]="vm.isGlobal"
                            (routeChanged)="toggleMenu()">
                        </atx-sidebar-teams-list>

                        <ng-container *ngIf="!vm.isGlobal && vm.team.status === 'active'">
                            <ion-menu-toggle autoHide="false">
                                <atx-sidebar-message-item></atx-sidebar-message-item>
                            </ion-menu-toggle>
                            <atx-sidebar-team-links
                                *ngIf="vm.links?.length || vm.team.admin"
                                [links]="vm.links"
                                (routeChanged)="toggleMenu()">
                            </atx-sidebar-team-links>
                        </ng-container>
                    </div>
                </ng-scrollbar>
            </ion-content>
        </ng-container>
        <ion-footer>
            <ion-toolbar>

                <div class="footer-container">
                    <ng-container *ngIf="vm$ | async as vm">
                        <ion-button *ngIf="vm.user.profile_completed"
                            (click)="toggleMenu()"
                            [routerLink]="['/user/' + vm.user.id]"
                            fill="clear">

                            <atx-user-avatar [avatar]="vm.user.avatar" class="small">
                            </atx-user-avatar>

                            <span class="mx-1">Profile</span>
                        </ion-button>

                        <ion-button id="settings-button" fill="clear">
                            <span class="mx-1">Settings</span>
                            <ion-icon name="chevron-down-outline"></ion-icon>
                        </ion-button>

                        <ion-popover trigger="settings-button" [dismissOnSelect]="true" side="top"
                            class="settings-items">
                            <ng-template>
                                <ion-content>
                                    <ion-list lines="none">
                                        <ion-item [routerLink]="['/edit-profile']"
                                            (click)="toggleMenu()"
                                            [button]="true"
                                            [detail]="false">
                                            <span>Account Settings</span>
                                        </ion-item>

                                        <ion-item *ngIf="vm.user.profile_completed"
                                            [routerLink]="['/teams-list']"
                                            (click)="toggleMenu()"
                                            [button]="true"
                                            [detail]="false">
                                            <span>Network Settings</span>
                                        </ion-item>

                                        <ion-item *ifAdmin="vm.plan === 'tiered'" [routerLink]="['payment/subscriptions/' + vm.team.id]"
                                            (click)="toggleMenu()"
                                            [button]="true"
                                            [detail]="false">
                                            <span>Subscriptions</span>
                                        </ion-item>

                                        <ion-item [href]="'mailto:connect@strya.co'" target="_blank"
                                            (click)="toggleMenu()" [button]="true" [detail]="false">
                                            <span>Help</span>
                                        </ion-item>

                                        <ion-item (click)="logout()" [button]="true" [detail]="false">
                                            <span>Sign Out</span>
                                        </ion-item>

                                    </ion-list>
                                </ion-content>
                            </ng-template>
                        </ion-popover>
                    </ng-container>
                </div>

            </ion-toolbar>
        </ion-footer>

    </ion-menu>

    <div class="ion-page" id="main">

        <ion-header [translucent]="true" class="top nav-bar">
            <ion-toolbar  class="athletex-theme main-user-data w-100" >
                <ion-buttons slot="start">
                    <ion-menu-button></ion-menu-button>
                </ion-buttons>

                <ion-item *ngIf="(vm$ | async) as vm" lines="none" class="sidebar-menu-item ion-no-padding d-none-lg">
                    <atx-user-avatar
                        [avatar]="vm.user.avatar"
                        [routerLink]="vm.user.profile_completed ? ['/user/' + vm.user.id] : undefined"
                        slot="start">
                    </atx-user-avatar>
                    <ion-label class="ion-no-margin">
                        <ion-text class="d-flex a-i-center j-c-end">
                            <ng-container *ngFor="let team of vm.user.teams | slice : 0 : 2">
                                <ion-avatar class="team-avatar" (click)="onTeamClicked(team)">
                                    <img [src]="team.logo_link">
                                </ion-avatar>
                            </ng-container>
                        </ion-text>
                    </ion-label>
                </ion-item>
            </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true">
            <router-outlet></router-outlet>
        </ion-content>
    </div>

</ion-split-pane>
