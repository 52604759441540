import { FormGroup, ValidationErrors } from '@angular/forms';

export const hasIndustries = (formGroup: FormGroup): ValidationErrors => {
    const industries = formGroup.get('industries');

    if (industries?.value.length) {
        industries?.setErrors(null);

    } else {
        industries?.setErrors({
            noIndusties: true
        });
    }

    industries?.markAsUntouched();
    industries?.markAsPristine();

    return {
        ...industries?.errors
    };
};
