/**
 * Example video urls:
 * https://vimeo.com/347119375
 * https://www.youtube.com/watch?v=vx5GlT2HyFE
 * https://youtu.be/vx5GlT2HyFE
 * https://www.hudl.com/video/3/11303614/61dbc150ff0c3b084c9b6ecf
 */

export enum VideoType {
    YOUTUBE = 'youtube',
    VIMEO = 'vimeo',
    // HUDL = 'hudl'
}

export class VideoHelpers {
    public static extractYoutubeId(url: string) {
        const regExp = /^.*(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})/;
        // const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[1].length === 11) ? match[1] : null;
    }

    public static extractVimeoId(url: string) {
        // const regExp = /http:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
        const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
        const match = url.match(regExp);

        return (match && match.length > 4) ? match[5] : null;
    }

    public static extractHudlId(url: string) {
        const regExp = /^.*(hudl\.com\/)(v\/|video\/)([A-z0-9]+)/;
        const match = url.match(regExp);

        return (match && match.length > 3) ? match[3] : null;
    }

    public static inspectVideoUrl(url: string) {
        try {
            new URL(url);
        } catch (error) {
            return null;
        }

        let id = VideoHelpers.extractYoutubeId(url);
        if (id) {
            return {
                videoId: id,
                videoType: VideoType.YOUTUBE
            };
        }

        id = VideoHelpers.extractVimeoId(url);
        if (id) {
            return {
                videoId: id,
                videoType: VideoType.VIMEO
            };
        }

        // id = VideoHelpers.extractHudlId(url);
        // if (id) {
        //     return {
        //         videoId: id,
        //         videoType: VideoType.HUDL
        //     };
        // }

        return null;
    }
}
