import { FormGroup, ValidationErrors } from '@angular/forms';

export const passwordsMatch = (formGroup: FormGroup): ValidationErrors => {
    const password = formGroup.get('password');
    const passwordConfirmation = formGroup.get('password_confirmation');

    if (!password?.value && !passwordConfirmation?.value) {
        return {};
    }

    if (password?.value === passwordConfirmation?.value || !password?.value) {
        passwordConfirmation?.setErrors(null);
    } else {
        passwordConfirmation?.setErrors({
            passwordsDoNotMatch: true
        });
    }

    return {
        ...passwordConfirmation?.errors
    };
};
