import { Component } from '@angular/core';
import { AppService } from './services/app.service';

@Component({
    selector: 'atx-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    constructor(
        private _app: AppService, // Keep
    ) {
    }
}
