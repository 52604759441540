import { Inject, Injectable, Optional } from '@angular/core';
import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { TeamService } from './team.service';
import { tap } from 'rxjs';
import { hasPotentialTeamSlug } from '../routes';

@Injectable({
    providedIn: 'root'
})
export class InitializerService {
    constructor(
        private _teamService: TeamService,
        @Optional()
        @Inject(APP_BASE_HREF) private _teamSlug: string,
        @Inject(DOCUMENT) private _document: Document
    ) {
    }

    public initialize$() {
        return this._teamService.load(this._teamSlug)
            .pipe(
                tap((hasTeam) => {
                    if (hasTeam === false && hasPotentialTeamSlug()) {
                        this._redirectToGeneralLanding();
                    }
                })
            );

    }

    private _redirectToGeneralLanding() {
        const currentPath = this._document.location.pathname;
        if (!['', '/'].includes(currentPath)) {
            alert('Unrecognized url, please check if you are using proper team link');
            const redirect = this._document?.baseURI;
            this._document.location.href = redirect;

        }
    }
}
