import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

const ERROR_ALERT_COOLDOWN = 1000 * 5; // 5 seconds

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
    private _lastErrorAlertTimestamp = 0;

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status >= 500) {
                    this._onError(error);
                }

                if (error?.error?.error) {
                    const err = error.error.error;
                    return throwError(err);
                }

                const errMessage = error.status + ': ' + error.statusText;
                console.error(error);

                return throwError(errMessage);

            }));
    }

    private _onError(error: any, message = 'Server error') {
        console.error(error);
        const now = new Date().getTime();
        if (now - this._lastErrorAlertTimestamp > ERROR_ALERT_COOLDOWN) {
            this._lastErrorAlertTimestamp = now;
            // alert(message);
        }
    }
}
