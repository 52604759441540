<ion-header [translucent]="true">
    <ion-toolbar color="dark">
        <ion-buttons slot="start">
            <ion-button (click)="dismissModal()" class="dismiss-btn">
                <ion-icon name="close-circle-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="ion-text-center">
            Crop Image
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="cropImage()" class="apply-btn">
                <span>Apply</span>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" color="dark" scrollX="false" scrollY="false">

    <ion-grid *ngIf="image">

        <ion-row class="ion-justify-content-center">
            <ion-col class="ion-no-padding">
                <div class="cropper-container">
                    <image-cropper #cropper
                        [imageBase64]="image"
                        [maintainAspectRatio]="maintainAspectRatio"
                        [aspectRatio]="aspectRatio"
                        [format]="compression"
                        format="png"
                        [hideResizeSquares]="isMobile"
                        [transform]="transform"
                        [autoCrop]="false">
                    </image-cropper>
                </div>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="isMobile">
            <ion-col size="12">
                <ion-text>
                    <p style="color: #ddd; font-size: .9rem; text-align: center;">Pinch image to zoom</p>
                </ion-text>
            </ion-col>
        </ion-row>

    </ion-grid>

    <ion-fab vertical="bottom" horizontal="center" slot="fixed">
        <ion-fab-button (click)="rotate()" size="small" color="dark">
            <ion-icon name="reload-outline"></ion-icon>
        </ion-fab-button>
    </ion-fab>

</ion-content>
