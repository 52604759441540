import { Component, Input } from '@angular/core';
import { DirectoryService } from '@athlete-x/features/directory/services/directory.service';
import { TeamStore } from '@athlete-x/stores';
import { combineLatest, map, tap } from 'rxjs';

@Component({
    selector: 'atx-user-avatar',
    templateUrl: 'user-avatar.component.html',
    styleUrls: ['user-avatar.component.scss']
})
export class UserAvatarComponent {
    @Input() avatar!: string;

    public vm$ = combineLatest([this._teamStore.team$, this._directory.isGlobal$])
        .pipe(
            map(([team, isGlobal]) => ({ logoLink: team.logo_link, isGlobal })),
            // tap((response) => console.log('User Avatar, ', response))
        );

    constructor(
        private _teamStore: TeamStore,
        private _directory: DirectoryService
    ) {
    }

}
