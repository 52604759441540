import { Injectable } from '@angular/core';
import { AUTOCOMPLETE_ENDPOINTS, AutocompleteType, Job } from '@athlete-x/definitions';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs';


@Injectable()
export class JobPickerService {

    constructor(
        private _http: HttpClient,
    ) {
    }

    public fetchItems(query: string, type: AutocompleteType, allowCreation: boolean) {
        const params = {
            query
        };

        return this._http.get<any>(AUTOCOMPLETE_ENDPOINTS[type], { params })
            .pipe(
                map((data) => data?.data || data),
                map((items) => {
                    for(const item of items) {
                        if (item.nickname) {
                            item.name = item.nickname;
                        }
                    }

                    return items;
                }),
                map((items: Array<Job>) => {
                    const isOnTheList = items
                        .filter((item) => item?.title?.toLocaleLowerCase() === query.toLocaleLowerCase());

                    if (!isOnTheList?.length && allowCreation) {
                        items.unshift({
                            title: query,
                            type: ''
                        });
                    }

                    return [
                        ...items
                    ];
                })
            );
    }
}
