import { Pipe, PipeTransform } from '@angular/core';
import { UtilitiesService } from '@athlete-x/services/utilities.service';

@Pipe({
    name: 'sortTeams'
})
export class SortTeamsPipe implements PipeTransform {

    constructor(
        private _utils: UtilitiesService
    ) {
    }

    transform(teams: Array<any>): Array<any> {
        if (!teams?.length) {
            return teams;
        }

        const colleges = teams.filter((t) => t.college);
        const highSchools = teams.filter((t) => t.high_school);
        const groups = teams.filter((t) => t.group);

        return [
            ...this._utils.sortBy(colleges, 'name'),
            ...this._utils.sortBy(highSchools, 'name'),
            ...this._utils.sortBy(groups, 'name'),
        ];

    }

}
