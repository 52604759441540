<form [formGroup]="form" autocomplete="off" class="form-container">

    <ion-item class="input-container mb-2" lines="none">
        <ion-text class="toggle-label">Only people from my current location</ion-text>
        <div class="toggle-container">
            <ion-text>
                <span>Off</span>
            </ion-text>
            <ion-toggle [formControlName]="'privacy_only_my_city'"
                mode="ios"
                style="font-size: .8rem;">
            </ion-toggle>
            <ion-text>
                <span>On</span>
            </ion-text>
        </div>
    </ion-item>

    <ion-item class="input-container mb-2" lines="none">
        <ion-text class="toggle-label">Only people from my industry</ion-text>
        <div class="toggle-container">
            <ion-text>
                <span>Off</span>
            </ion-text>
            <ion-toggle [formControlName]="'privacy_only_my_industry'"
                mode="ios"
                style="font-size: .8rem;">
            </ion-toggle>
            <ion-text>
                <span>On</span>
            </ion-text>
        </div>
    </ion-item>


    <ion-item class="input-container mb-2" lines="none">
        <ion-text class="toggle-label">Only people from my college</ion-text>
        <div class="toggle-container">
            <ion-text>
                <span>Off</span>
            </ion-text>
            <ion-toggle [formControlName]="'privacy_only_my_college'"
                mode="ios"
                style="font-size: .8rem;">
            </ion-toggle>
            <ion-text>
                <span>On</span>
            </ion-text>
        </div>
    </ion-item>


    <ion-item class="input-container mb-2" lines="none">
        <ion-text class="toggle-label">Only people from my sport</ion-text>
        <div class="toggle-container">
            <ion-text>
                <span>Off</span>
            </ion-text>
            <ion-toggle [formControlName]="'privacy_only_my_sport'"
                mode="ios"
                style="font-size: .8rem;">
            </ion-toggle>
            <ion-text>
                <span>On</span>
            </ion-text>
        </div>
    </ion-item>

</form>
