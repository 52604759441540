export const staging = {
    locations: [
        {
            name: 'Baltimore',
            id: 1
        },
        {
            name: 'New York',
            id: 2
        },
        {
            name: 'Philadelphia',
            id: 3
        },
        {
            name: 'San Francisco',
            id: 4
        }
    ],

    industries: [
        {
            id: 8,
            name: 'Consulting',
            type: 'company'
        },
        {
            id: 4,
            name: 'Finance',
            type: 'company'
        },
        {
            id: 5,
            name: 'Sales',
            type: 'company'
        },
        {
            id: 7,
            name: 'Real Estate',
            type: 'company'
        },
        {
            id: 6,
            name: 'Tech',
            type: 'company'
        }
    ]
};

export const production = {
    locations: [
        {
            name: 'Baltimore',
            id: 1
        },
        {
            name: 'New York',
            id: 2
        },
        {
            name: 'Philadelphia',
            id: 3
        },
        {
            name: 'San Francisco',
            id: 4
        }
    ],

    industries: [
        {
            id: 11,
            name: 'Consulting',
            type: 'company'
        },
        {
            id: 6,
            name: 'Finance',
            type: 'company'
        },
        {
            id: 8,
            name: 'Sales',
            type: 'company'
        },
        {
            id: 9,
            name: 'Real Estate',
            type: 'company'
        },
        {
            id: 2,
            name: 'Tech',
            type: 'company'
        }
    ]
};
