import { DOCUMENT } from '@angular/common';

import { Component, Input, Inject, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TeamLink } from '@athlete-x/definitions';

@Component({
    selector: 'atx-sidebar-team-links',
    templateUrl: 'sidebar-team-links.component.html',
    styleUrls: ['sidebar-team-links.component.scss']
})
export class SidebarTeamLinksComponent {

    @Input() links: Array<TeamLink> = [];
    @Output() routeChanged = new EventEmitter();

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _router: Router
    ) {
    }

    public redirectToTeam(slug: string) {
        const redirect = this._document?.baseURI + slug;
        this._document.location.href = redirect;
    }

    public goToLink(link: string) {
        link = link.includes('https://') ? link : 'https://' + link;
        window.open(link, '_blank');
    }

    public openTeamLinks() {
        this._router
            .navigate(['/team-links'])
            .then(() => this.routeChanged.emit(true));
    }

}
