import { FormGroup, ValidationErrors } from '@angular/forms';

export const emailsMatch = (formGroup: FormGroup): ValidationErrors => {
    const email = formGroup.get('email');
    const emailConfirmation = formGroup.get('email_confirmation');

    if (!email?.value && !emailConfirmation?.value) {
        return {};
    }

    if (email?.value === emailConfirmation?.value || !email?.value) {
        emailConfirmation?.setErrors(null);
    } else {
        emailConfirmation?.setErrors({
            emailDoNotMatch: true
        });
    }

    return {
        ...emailConfirmation?.errors
    };
};
