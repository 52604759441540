import { Injectable } from '@angular/core';
import { AspectRatioTypes, CompresionTypes, ImageSizes } from '@athlete-x/definitions';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ImageCropperService } from '@athlete-x/features/image-cropper';

@Injectable({
    providedIn: 'root'
})
export class ImageService {
    constructor(private _imageCropperService: ImageCropperService) {
    }

    public takeImageAsBase64(size: ImageSizes, compression?: CompresionTypes): Observable<string> {
        return from(Camera.getPhoto({
            source: CameraSource.Prompt,
            quality: 100,
            allowEditing: false,
            resultType: CameraResultType.DataUrl,
            webUseInput: true
        }))
            .pipe(
                switchMap((photo: Photo): Promise<string> => {
                    const dataUrl = photo.dataUrl;

                    if (dataUrl) {
                        return this.readMaxSizeImageFile(dataUrl, size, compression);
                    }

                    return new Promise((resolve) => resolve(''));
                })
            );

    }

    public takeCroppedImageAsBase64(aspectRatio: AspectRatioTypes, size: ImageSizes, compression: CompresionTypes = CompresionTypes.PNG) {
        return this.takeImageAsBase64(size, compression)
            .pipe(
                switchMap((base64: string) => {
                    if (base64) {
                        return this._imageCropperService.cropToBase64(base64, aspectRatio, compression);

                    } else {
                        return of(null);
                    }
                })
            );
    }

    public readMaxSizeImageFile(dataUrl: string, size: ImageSizes, compression: CompresionTypes = CompresionTypes.PNG): Promise<string> {

        return new Promise((resolve) => {
            const img = document.createElement('img');
            // const img = new Image();
            img.src = dataUrl;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                if (img.width > img.height) {
                    const ratio = img.width / size;
                    canvas.width = size;
                    canvas.height = img.height / ratio;

                } else {
                    const ratio = img.height / size;
                    canvas.height = size;
                    canvas.width = img.width / ratio;
                }

                // We resize the image with the canvas method drawImage();
                if (ctx) {
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    const resizedDataUrl = canvas.toDataURL('image/' + compression);

                    resolve(this._dataUrlToBase64(resizedDataUrl));
                } else {
                    resolve('');
                }

            };

            // We put the Data URI in the image's src attribute
            // img.src = dataUrl;
        });


    }

    private _dataUrlToBase64(dataUrl: string): string {
        return dataUrl.split('base64,')[1];
    }


    public uploadContent(file: File) {
        const myReader: FileReader = new FileReader();
        myReader.readAsDataURL(file);

        return new Promise((resolve) => {
            myReader.onloadend = () => {
                const dataUrl = myReader.result;
                resolve(dataUrl);
            };
        });
    }


}
