import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    private _loading!: any;
    constructor(
        private _loadingController: LoadingController,
    ) {
    }

    public show(message?: string) {
        if (!this._loading) {
            this._loading = this._loadingController
                .create({
                    cssClass: 'loading-custom-class',
                    message: message || '',
                    spinner: 'circles'
                })
                .then((loader) => {
                    loader.present();
                });
        }

        return this._loading;
    }

    public hide() {
        return new Promise((resolve) => {
            setTimeout(() => {
                if (this._loading) {
                    this._loading = null;
                    this._loadingController.dismiss();
                }
                resolve(null);
            }, 500);
        });
    }

}


