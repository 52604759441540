import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { UserAvatarComponent } from './user-avatar.component';

@NgModule({
    declarations: [
        UserAvatarComponent
    ],
    imports: [
        IonicModule,
        CommonModule,

    ],
    exports: [
        UserAvatarComponent
    ]
})
export class UserAvatarModule {
}
