import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AutocompleteModalComponent } from '../components/autocomplete-modal/autocomplete-modal';
import { AUTOCOMPLETE_ENDPOINTS, AutocompleteItem, AutocompleteType } from '@athlete-x/definitions';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs';
import { UtilitiesService } from '@athlete-x/shared';

@Injectable()
export class AutocompleteService {

    constructor(
        private _http: HttpClient,
        private _modalCtrl: ModalController,
        private _utilities: UtilitiesService
    ) {
    }

    public fetchItems(query: string, type: AutocompleteType, allowCreation: boolean) {
        const params = {
            query
        };

        return this._http.get<any>(AUTOCOMPLETE_ENDPOINTS[type], { params })
            .pipe(
                map((data) => data?.data || data),
                map((items) => {
                    for(const item of items) {
                        if (item.nickname) {
                            item.name = item.nickname;
                        }
                    }

                    return this._utilities.sortBy(items, 'name');
                }),
                map((items: Array<AutocompleteItem>) => {
                    const isOnTheList = items
                        .filter((item) => item?.name?.toLocaleLowerCase() === query.toLocaleLowerCase());

                    if (!isOnTheList?.length && allowCreation) {
                        items.unshift({
                            id: 'new',
                            name: query,
                            type: type
                        });
                    }

                    return [
                        ...items
                    ];
                })
            );
    }

    public async openAutocompleteModal(componentProps: any) {

        return this._modalCtrl
            .create({
                component: AutocompleteModalComponent,
                componentProps
            })
            .then((modal) => {
                modal.present();
                return modal.onWillDismiss();
            })
            .then(({ data }) => data || null);
    }

}
