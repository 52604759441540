import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, repeat, shareReplay, startWith, Subject, switchMap, tap } from 'rxjs';
import { ENDPOINTS, Pagination, Subscription, SubscriptionType } from '@athlete-x/definitions';
import { UtilitiesService } from '@athlete-x/shared';
import { TeamStore } from '@athlete-x/stores';


@Injectable({
    providedIn: 'root'
})
export class PaymentsService {
    private _pagination!: Pagination;

    private _reloadSubscriptions$ = new Subject<void>();
    public subscriptions$ = this._team.teamId$
        .pipe(
            switchMap((teamId) => this._loadSubscriptions(teamId)
                .pipe(repeat({ delay: () => this._reloadSubscriptions$ }))
            ),
            shareReplay(1),
            map((subscriptionsData: { data: Array<Subscription>, pagination: Pagination }) => {
                const { data, pagination } = subscriptionsData;
                this._pagination = pagination;

                return data;
            }),
        );

    public isLoaded$ = this.subscriptions$
        .pipe(
            startWith(false),
            map((value) => !!value)
        );

    public activeSubscription$ = this.subscriptions$
        .pipe(
            map((subscriptions) => subscriptions.find((subscription) => subscription.general_status === 'active')),
            startWith(undefined)
        );

    public adminData$ = this._team.adminData$;

    constructor(
        private _http: HttpClient,
        private _utilities: UtilitiesService,
        private _team: TeamStore
    ) {
    }

    public loadSubscriptions(teamId: number) {
    }

    public reload() {
        this._reloadSubscriptions$.next();
    }

    private _loadSubscriptions(teamId: number) {
        const params = {
            team_id: teamId
        };

        return this._http.get<{ data: Array<Subscription>, pagination: Pagination }>(ENDPOINTS.getSubscriptions, {
            params
        });
    }

    public manageSubscription(subscriptionId: string, teamId: number) {
        return this._http.post(ENDPOINTS.menageSubscription, {
            subscription_id: subscriptionId,
            team_id: teamId
        })
            .subscribe({
                next: (linkData: any) => this._openSessionLink(linkData, '_parent')
            });
    }

    public cancelSession(subscriptionId: string) {
        return this._http.post(ENDPOINTS.expireSubscriptionSession, {
            subscription_id: subscriptionId,
        }).pipe(
            tap(() => {
                this.reload();
            })
        );
    }

    public checkoutsSession(billing_period: SubscriptionType) {
        return this._checkoutsSession(billing_period)
            .subscribe({
                next: (linkData: any) => this._openSessionLink(linkData, '_parent'),
                error: () => this._onCheckoutError()
            });
    }

    private _checkoutsSession(billing_period: SubscriptionType) {
        return this._http.post(ENDPOINTS.checkoutsSession, {
            billing_period,
            team_id: this._team.id
        });
    }

    private _openSessionLink(linkData: { session_url: string, session_id: string }, target: string) {
        const {
            session_url
        } = linkData;

        window.open(session_url, target);
    }

    private _onCheckoutError() {
        const content = {
            header: 'Checkout error',
            message: 'Your request cannot be processed',
            buttons: ['Ok']
        };

        this._utilities.presentAlert(content);
    }
}
