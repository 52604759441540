import { Pipe, PipeTransform } from '@angular/core';
import { College, Conglomerate, HighSchool, Team, TeamType } from '@athlete-x/definitions';
import { TeamHelper } from '@athlete-x/shared/classes/TeamHelper';


interface GroupedTeams {
    [key: string]: {
        parent?: HighSchool | College | Conglomerate;
        items: Array<Team>;
        clickable?: boolean;
    }
}

@Pipe({
    name: 'groupTeams',
    standalone: true
})
export class GroupTeamsPipe implements PipeTransform {

    transform(teams: Array<any>, useConglomerates = false): Array<any> {
        if (!teams?.length) {
            return teams;
        }

        const conglomerates: GroupedTeams = {};
        const colleges: GroupedTeams = {};
        const high_schools: GroupedTeams = {};
        const groups: { items: Array<Team> } = {
            items: []
        };

        for(const team of teams) {
            if (useConglomerates && team.conglomerate) {
                const { conglomerate } = team;
                if (!conglomerates[conglomerate.id]) {
                    conglomerates[conglomerate.id] = {
                        parent: conglomerate,
                        items: []
                    };
                }
                conglomerates[conglomerate.id].items.push(team);
                continue;
            }

            const type = TeamHelper.teamType(team);
            if (type === TeamType.COLLEGE) {
                if (!colleges[team[type].id]) {
                    colleges[team[type].id] = {
                        clickable: true,
                        parent: team[type],
                        items: []
                    };
                }
                colleges[team[type].id].items.push(team);

            } else if (type === TeamType.HIGH_SCHOOL) {
                if (!high_schools[team[type].id]) {
                    high_schools[team[type].id] = {
                        clickable: true,
                        parent: team[type],
                        items: []
                    };
                }
                high_schools[team[type].id].items.push(team);

            } else {
                groups.items.push(team);
            }
        }

        return [
            ...Object.values(conglomerates),
            ...Object.values(colleges),
            ...Object.values(high_schools),
            groups
        ];
    }

}
