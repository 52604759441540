import { DOCUMENT } from '@angular/common';
import { Component, Input, Inject, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Team, User } from '@athlete-x/definitions';
import { ModalController } from '@ionic/angular';
import { DirectoryTypeModalComponent } from '../directory-type-modal/directory-type-modal.component';
import { IS_MOBILE } from '@athlete-x/shared';

@Component({
    selector: 'atx-sidebar-teams-list',
    templateUrl: 'sidebar-teams-list.component.html',
    styleUrls: ['sidebar-teams-list.component.scss']
})
export class SidebarTeamsListComponent {
    @Input() user!: User;
    @Input() currentTeam!: Team;
    @Input() isGlobal!: boolean;

    @Output() routeChanged = new EventEmitter();

    public isMobile = this._isMobile;

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        @Inject(IS_MOBILE) private _isMobile: boolean,
        private _router: Router,
        private _modalCtrl: ModalController
    ) {
    }


    public onTeamClicked(team?: Team) {
        if (!team) {
            this._openGlobal();
        } else {
            const slug = team.slug;
            const currentTeamSlug = this.currentTeam.slug;

            if (team.status !== 'active' && slug === currentTeamSlug) {
                return;
            }

            if (slug === currentTeamSlug) {
                this._navigate('private');

            } else {
                this._navigateAndRefresh(slug);
            }
        }
    }

    private _openGlobal() {
        const active = this.user.teams.reduce((acc, team) => {
            return acc || team.status === 'active';
        }, false);

        if (!active) {
            alert('In order to access Global, you must be an approved member of at least one team network.');

        } else if (this.user.global) {
            this._navigate('global');

        } else {
            this._changeGlobalAccessSettings()
                .then(global => {
                    if (global) {
                        this._navigate('global');
                    }
                });
        }
    }

    public changeUserDirectoryType(event: any) {
        event.stopPropagation();
        event.preventDefault();

        this._changeGlobalAccessSettings()
            .then(global => {
                if (!global && this.currentTeam.status !== 'pending') {
                    this._navigate('private');
                }
            });
    }

    private _changeGlobalAccessSettings() {
        return this._modalCtrl
            .create({
                component: DirectoryTypeModalComponent,
                cssClass: 'directory-type-modal'
            })
            .then(modal => {
                modal.present();
                return modal.onDidDismiss();
            })
            .then(({ data, role }) => {
                if (role === 'confirm') {
                    const { global } = data;
                    return global;
                }
            });
    }

    private _navigate(param: string) {
        this._router
            .navigate([param === 'global' ? 'global' : '/'])
            .then(() => this.routeChanged.emit(true));

    }

    private _navigateAndRefresh(slug: string) {
        const redirect = this._document?.baseURI + slug;
        this._document.location.href = redirect;
    }

    public navigateToTeamsListEdit(event: any) {
        event.stopPropagation();
        event.preventDefault();

        this._router
            .navigate(['./teams-list'])
            .then(() => this.routeChanged.emit(true));
    }

    public navigateToWaitList(event: any, teamSlug: string) {
        event.stopPropagation();
        event.preventDefault();

        const currentTeamSlug = this.currentTeam.slug;

        if (currentTeamSlug === teamSlug) {
            this._router.navigate(['/waitlist']);
        } else {
            this._navigateAndRefresh(teamSlug + '/waitlist');
        }

    }

    trackByTeamId(index: number, team: Team): number {
        return team.id;
    }

    public onGroupClicked(group: any) {
        return;
        const { meta, ...school} = group.parent;
        this._router.navigate(['global'], {
            queryParams: school
        }).then(() => this.routeChanged.emit(true));
    }


}
