import { Injectable } from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class PaginationInHeadersInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<never>, next: HttpHandler) {
        return next.handle(req)
            .pipe(map((response) => {
                if(response.type === 0){
                    return response;
                }

                const result = response as any;

                if (response instanceof HttpResponse) {
                    if(response.headers.has('Total-Count')) {
                        const pagination = {
                            totalCount: response.headers.get('Total-Count'),
                            totalPages: response.headers.get('Total-Pages'),
                            pageItems: response.headers.get('Page-Items')
                        };

                        result.body = {
                            data: response.body,
                            pagination
                        };
                    }
                }



                return result;
            }));
    }

}
