import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Team } from '@athlete-x/definitions';
import { AuthService } from '@athlete-x/features/auth/services/auth.service';
import { DirectoryService } from '@athlete-x/features/directory';
import { TeamStore, UserStore } from '@athlete-x/stores';
import { MenuController } from '@ionic/angular';
import { combineLatest, map, tap } from 'rxjs';

@Component({
    selector: 'atx-authenticated',
    templateUrl: 'authenticated.component.html',
    styleUrls: ['authenticated.component.scss'],
})
export class AuthenticatedComponent {

    private _currentTeam!: Team ;
    public vm$ = combineLatest([
        this._userStore.user$,
        this._userStore.currentWorkPosition$,
        this._teamStore.team$,
        this._teamStore.links$,
        this._teamStore.teamPlan$,
        this._directoryService.isGlobal$
    ])
        .pipe(
            map(([user, currentCompany, team, links, plan, isGlobal]) => ({
                user,
                currentCompany,
                jobsTitles: currentCompany?.title.split(', ').filter(jb => jb),
                team,
                links,
                plan,
                isGlobal
            })),
            tap((data) => this._currentTeam = data.team),
            // tap((data) => console.log('Authenticated ==> ', data))
        );

    constructor(
        private _auth: AuthService,
        private _userStore: UserStore,
        private _teamStore: TeamStore,
        private _directoryService: DirectoryService,
        private _menuCtrl: MenuController,
        private _router: Router,
        @Inject(DOCUMENT) private _document: Document,
    ) {
    }

    public logout() {
        this._auth.logout(true, true);
    }

    public toggleMenu() {
        this._menuCtrl.toggle('main-menu');
    }

    public onTeamClicked(team?: Team) {
        if (team) {
            const slug = team.slug;
            const currentTeamSlug = this._currentTeam.slug;

            if (team.status !== 'active' && slug === currentTeamSlug) {
                return;
            }

            if (slug === currentTeamSlug) {
                this._navigate('private');

            } else {
                this._navigateAndRefresh(slug);
            }
        }
    }

    private _navigateAndRefresh(slug: string) {
        const redirect = this._document?.baseURI + slug;
        this._document.location.href = redirect;
    }

    private _navigate(param: string) {
        this._router
            .navigate([param === 'global' ? 'global' : '/']);
    }


}
