import { Directive, Input } from '@angular/core';
import { IfUserIsDirective } from './if-user-is.directive';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ifAdmin]'
})
export class IfAdminDirective extends IfUserIsDirective {
    private _showIfAdmin = true;
    @Input() set ifAdmin(show: boolean) {
        this._showIfAdmin = show;
        this._update();
    }

    protected override get _shouldRender() {
        return this._teamStore.admin ? this._showIfAdmin : false;
    }
}
