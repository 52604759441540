import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SchoolAutocompleteComponent } from './components/school-autocomplete/school-autocomplete';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { IonicModule } from '@ionic/angular';
import { SchoolAutocompleteService } from './services/school-autocomplete.service';
import { JoinPipe } from '@athlete-x/shared/pipes/join.pipe';

@NgModule({
    declarations: [
        SchoolAutocompleteComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AutocompleteLibModule,
        JoinPipe
    ],
    exports: [
        SchoolAutocompleteComponent
    ],
    providers: [
        SchoolAutocompleteService
    ]
})
export class SchoolAutocompleteModule {
}
