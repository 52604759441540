<ion-header>
    <ion-toolbar>
        <ion-title>New Post</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="cancel()">
                <ion-icon name="close-circle-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="athletex-theme">
        <form [formGroup]="form" autocomplete="off" class="form-container">

            <ion-grid style="padding: 1rem;">

                <ion-row class="ion-justify-content-center form-section">

                    <ion-col size="12" *ngIf="isNotTeamGroup$ | async">

                        <ion-item class="input-container" lines="none">
                            <ion-select [formControlName]="'target_group'"
                                multiple="true"
                                justify="space-between"
                                label="Recipients"
                                placeholder="Select"
                                class="w-100"
                                [interfaceOptions]="{ header: 'Recipients' }">

                                <ion-select-option *ngFor="let item of usersTypes$ | async" [value]="item.value">
                                    {{ item.label }}
                                </ion-select-option>

                            </ion-select>
                        </ion-item>

                    </ion-col>

                    <ion-col size="12">

                        <div class="floating-input-container">
                            <ion-textarea
                                [label]="'Message'"
                                placeholder="Message"
                                labelPlacement="floating"
                                fill="outline"
                                autosize
                                [formControlName]="'message'">
                            </ion-textarea>
                        </div>

                    </ion-col>

                    <ion-col size="12">
                        <ion-item *ngIf="attachmentFileName" class="input-container" lines="none">
                            <ion-text class="d-flex a-i-center">
                                <ion-icon name="checkmark-outline" class="mr-1" color="success"></ion-icon>
                                <p>Attachment Link: <strong>{{attachmentFileName}}</strong></p>
                            </ion-text>
                        </ion-item>
                    </ion-col>

                    <ion-col size="12">
                        <ion-button (click)="fileInput.click()" expand="block" fill="outline" color="dark"
                            class="upload-button">
                            <span>{{attachmentFileName ? 'Change File' : 'Upload File'}}</span>
                        </ion-button>

                        <input (change)="uploadFile($event)"
                            hidden
                            #fileInput
                            type="file"
                            accept="image/png, image/jpeg, image/jpg, application/pdf"
                            id="file">
                    </ion-col>

                </ion-row>

            </ion-grid>

        </form>
    </div>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="confirm()" [disabled]="form.invalid" size="block" color="primary" fill="outline"
                shape="round">

                <ion-label style="text-transform: capitalize; font-weight: 400;">Post</ion-label>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
