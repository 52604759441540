import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { TeamBannerComponent } from './components/team-banner.component';
import { UpdateProfileModule } from '@athlete-x/forms/update-profile/update-profile.module';
import { SharedModule } from '@athlete-x/shared';

@NgModule({
    declarations: [
        TeamBannerComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UpdateProfileModule,
        SharedModule
    ],
    exports: [
        TeamBannerComponent
    ]
})
export class TeamBannerModule {
}
