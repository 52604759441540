<form [formGroup]="form" autocomplete="off" class="form-container">

    <ion-item class="input-container mb-0" lines="none">
        <ion-text class="toggle-label">Global Directory Access</ion-text>
        <div class="toggle-container">
            <ion-text>
                <span>Off</span>
            </ion-text>
            <ion-toggle [formControlName]="'global'"
                mode="ios"
                style="font-size: .8rem;">
            </ion-toggle>
            <ion-text>
                <span>On</span>
            </ion-text>
        </div>

    </ion-item>
</form>
