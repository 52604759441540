import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { SharedModule } from '@athlete-x/shared';
import { TeamGenderPipe } from '@athlete-x/shared/pipes/gender-parser.pipe';
import { JoinPipe } from '@athlete-x/shared/pipes/join.pipe';
import { ProfileMainInfoComponent } from './components/profile-main-info/profile-main-info.component';
import { ProfileWorkInfoComponent } from './components/profile-work-info/profile-work-info.component';
import { ProfileSchoolInfoComponent } from './components/profile-school-info/profile-school-info.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { ProfileIntroVideoComponent } from './components/profile-intro-video/profile-intro-video.component';
import { UserAvatarModule } from '../user-avatar';
import { ColumnPipe } from '@athlete-x/shared/pipes/column.pipe';
import { RouterLink } from '@angular/router';

@NgModule({
    declarations: [
        ProfileMainInfoComponent,
        ProfileWorkInfoComponent,
        ProfileSchoolInfoComponent,
        ProfileIntroVideoComponent,
        VideoPlayerComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        SharedModule,
        TeamGenderPipe,
        JoinPipe,
        UserAvatarModule,
        ColumnPipe,
        RouterLink
    ],
    exports: [
        ProfileMainInfoComponent,
        ProfileWorkInfoComponent,
        ProfileSchoolInfoComponent,
        ProfileIntroVideoComponent,
        VideoPlayerComponent
    ]
})
export class ProfileComponentsModule {
}
