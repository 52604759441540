import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

const CLASS_YEARS_RANGE = 107;

export enum SortDirection {
    DESC = 'desc',
    ASCE = 'asce'
}
export const IS_MOBILE = new InjectionToken<string>('Is mobile view');

@Injectable({
    providedIn: 'root'
})
export class UtilitiesService {
    public get isMobile() { return this._isMobile; }

    constructor(
        @Inject(IS_MOBILE) private _isMobile: boolean,
        private _toastController: ToastController,
        private _alertController: AlertController
    ) {
    }

    public async presentToast(
        message: string,
        position: 'top' | 'middle' | 'bottom' = 'bottom',
        className?: 'status-ok' | 'status-error' | 'green-toast') {
        const toast = await this._toastController
            .create({
                message,
                duration: 2400,
                position: position,
                cssClass: className
            });

        await toast.present();

        return toast.onDidDismiss();
    }

    public filterEmptyFromObject(obj: any) {
        const filtered: { [key: string]: any } = {};
        Object.keys(obj)
            .map((key) => {
                if (!this._isEmptyValue(obj[key])) {
                    filtered[key] = obj[key];
                }
            });

        return filtered;
    }

    public filterZerosFromObject(obj: any) {
        const filtered: { [key: string]: any } = {};
        Object.keys(obj)
            .map((key) => {
                if (obj[key] !== 0) {
                    filtered[key] = obj[key];
                }
            });

        return filtered;
    }

    public filterFalsesFromObject(obj: any) {
        const filtered: { [key: string]: any } = {};
        Object.keys(obj)
            .map((key) => {
                if (obj[key] !== false) {
                    filtered[key] = obj[key];
                }
            });

        return filtered;
    }

    private _isEmptyValue(value: any) {
        return value === null || value === '' || value === undefined;
    }

    public makeClasses(offsetRange = 0) {
        const currentYear = (new Date()).getFullYear() + offsetRange;

        const classes = Array.from({ length: CLASS_YEARS_RANGE }).map((_, i) => (currentYear - i).toString());

        return classes;
    }

    public async presentAlert(content: any) {
        const {
            header,
            subHeader,
            message,
            buttons
        } = content;

        return this._alertController
            .create({
                header,
                subHeader,
                message,
                buttons,
            })
            .then((alert) => {
                alert.present();
                return alert.onDidDismiss();
            });

    }

    public checkIfIsDirectoryRoute(url: string) {
        let directoryWithoutParam: boolean;
        let directoryWithParam: boolean;

        const pathParts = url
            .split('/')
            .filter((e: string) => e);

        if (pathParts.length === 0) {
            directoryWithoutParam = true;
            directoryWithParam = false;
        } else {
            directoryWithoutParam = false;
            directoryWithParam = (pathParts[0]?.indexOf('?directory') >= 0) ? true : false;
        }

        return directoryWithoutParam || directoryWithParam;
    }

    public sortBy(values: Array<any>, field: string, direction?: string) {

        const directionValue = (direction && direction === SortDirection.DESC) ? -1 : 1;

        if (!values) {
            return values;
        }

        return [...values]
            .sort((a, b) => {
                a = (typeof a[field] === 'string') ? a[field].toLowerCase() : a[field];
                b = (typeof b[field] === 'string') ? b[field].toLowerCase() : b[field];

                if (a < b) {
                    return -1 * directionValue;
                }
                if (a > b) {
                    return 1 * directionValue;
                }
                return 0;
            });
    }

    public sortByLength(values: Array<any>, field: string) {
        return [...values]
            .sort((a, b) => {
                if (a[field]?.length < b[field]?.length) {
                    return -1;
                }
                if (a[field]?.length > b[field]?.length) {
                    return 1;
                }
                return 0;
            });
    }

    public uniqueArrayByKey(array: Array<any>, key: string) {
        return [
            ...new Map(array.map(item => [item[key], item])).values()
        ];
    }

    public membershipYears(yearsNumber?: number, year?: number, revert = false): Array<number> {
        const startYear = year ? +year : new Date().getFullYear();
        const yearsArray: Array<number> = [];

        if (yearsNumber && yearsNumber < 0) {
            for (let x = startYear; x >= startYear + yearsNumber; x--) {
                yearsArray.push(x);
            }
        } else if (yearsNumber && yearsNumber > 0) {
            for (let x = startYear + yearsNumber; x >= startYear; x--) {
                yearsArray.push(x);
            }
        } else {
            for (let x = startYear + 5; x > startYear - 100; x--) {
                yearsArray.push(x);
            }
        }

        if (revert) {
            yearsArray.reverse();
        }

        return yearsArray;
    }
}
