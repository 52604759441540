import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressAutocompleteComponent } from './address-autocomplete/address-autocomplete.component';
import { IonicModule } from '@ionic/angular';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { GoogleMapsService } from './services/google-maps.service';

@NgModule({
    declarations: [AddressAutocompleteComponent],
    exports: [AddressAutocompleteComponent],
    imports: [
        CommonModule,
        IonicModule,
        HttpClientModule,
        HttpClientJsonpModule
    ],
    providers: [
        GoogleMapsService
    ]
})
export class AddressAutocompleteModule {}
