import { Component } from '@angular/core';
import { User } from '@athlete-x/definitions';
import { UserStore } from '@athlete-x/stores';
import { ModalController } from '@ionic/angular';
import { tap } from 'rxjs';

@Component({
    selector: 'atx-directory-type-modal',
    templateUrl: './directory-type-modal.component.html',
    styleUrls: ['./directory-type-modal.component.scss'],
})
export class DirectoryTypeModalComponent {

    private _global!: boolean;
    public user$ = this._userStore.user$
        .pipe(tap((user: User) => this._global = user.global));


    constructor(
        private _modalCtrl: ModalController,
        private _userStore: UserStore
    ) {

    }

    public onDirectoryTypeChange(event: any) {
        this._global = event.global;
    }

    public cancel() {
        return this._modalCtrl.dismiss(null, 'cancel');
    }

    public confirm() {
        return this._modalCtrl.dismiss({ global: this._global }, 'confirm');
    }

}

