import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    BehaviorSubject,
    map,
    tap,
    of,
    withLatestFrom,
    take,
    switchMap,
    finalize,
    delay,
    distinctUntilChanged,
} from 'rxjs';
import { DirectoryUser, ENDPOINTS, Industry, Pagination, User } from '@athlete-x/definitions';
import { TeamStore } from '@athlete-x/stores';
import { UtilitiesService } from '@athlete-x/shared';
import { FilterHelpersService } from './filters-helper.service';

const ALLOW_LOAD_MORE = true;
const FIRST_PAGE = 1;
const DATA_PER_PAGE = 8;
const SORT_BY = 'first_name ASC';

@Injectable({
    providedIn: 'root'
})
export class DirectoryService {
    private _endReached$ = new BehaviorSubject(false);
    public endReached$ = this._endReached$.asObservable();

    private _data$ = new BehaviorSubject<Array<DirectoryUser>>([]);
    public data$ = this._data$.asObservable();
    public get data() { return this._data$.value; }
    public count$ = this._data$.asObservable().pipe(map((data) => data.length));

    private _totalCount$ = new BehaviorSubject<number>(0);
    public totalCount$ = this._totalCount$.asObservable();

    private _loading$ = new BehaviorSubject(true);
    public loading$ = this._loading$.asObservable();

    private _filters$ = new BehaviorSubject<any>({});
    public filters$ = this._filters$.asObservable();
    public get filters() { return this._filters$.value; }

    private _page: number = FIRST_PAGE;

    private _isGlobal$ = new BehaviorSubject(false);
    public isGlobal$ = this._isGlobal$.asObservable()
        .pipe(
            distinctUntilChanged(),
        );

    constructor(
        private _http: HttpClient,
        private _team: TeamStore,
        private _utilities: UtilitiesService,
        private _filterHelpers: FilterHelpersService
    ) {
    }

    public openDirectory(global: boolean, filters = {}) {
        this._isGlobal$.next(global);
        this._filterHelpers.init(global);

        this.reset(filters);
    }

    private _load(page: number) {
        let params = {
            team_id: this._team.id,
            per_page: DATA_PER_PAGE,
            sort: SORT_BY,
            page
        };

        if (this._filters$.value) {
            params = {
                ...params,
                ...this._removeUnnecessaryKeys(this._filters$.value)
            };
        }

        if (!this._loading$.value) {
            this._loading$.next(true);
        }

        const ENDPOINT = this._isGlobal$.value ? ENDPOINTS.usersGlobalDirectory : ENDPOINTS.usersDirectory;

        return this._http.post<{ data: Array<User>, pagination: Pagination }>(ENDPOINT, params)
            .pipe(
                map((response) => {
                    const { data, pagination } = response;
                    if (!data || !pagination) {
                        console.warn('NO DATA - is deseralizer in place?');
                    }
                    this._totalCount$.next(pagination.totalCount);
                    if (page === +pagination.totalPages) {
                        this._endReached$.next(true);
                    }

                    return data;
                }),
                map((users) => users.map((user) => {
                    const teamInfo = user.user_teams?.find((teamInfo) => teamInfo.team.id === this._team.id);
                    user.user_type = teamInfo?.user_type;
                    return user;
                })),
                withLatestFrom(this.data$),
                tap(([Data, oldData]) => {
                    if (page > FIRST_PAGE) {
                        Data = [
                            ...oldData,
                            ...Data
                        ];
                    }

                    this._data$.next(Data);
                }),
                delay(100),
                finalize(() => {
                    this._loading$.next(false);
                })
            );
    }

    public load() {
        this._page = FIRST_PAGE;
        return this._load(this._page);
    }

    public loadMore() {
        if (!ALLOW_LOAD_MORE) {
            console.warn('DIRECTORY LOADING MODE ---DISABLED---');
            return of([]);
        }

        return this.endReached$
            .pipe(
                take(1),
                switchMap((endReached) => {
                    if (endReached) {
                        return of(null);
                    }

                    this._page += 1;
                    return this._load(this._page);
                })
            );
    }

    public reset(initialFilters = {}) {
        this._data$.next([]);
        this._filters$.next(initialFilters);
        this._endReached$.next(false);
        this.load().subscribe();
    }

    public removeFilter(filterKey: string) {
        const filters = this._filters$.value;
        filters[filterKey] = null;
        this.updateFilters(filters);
    }

    public addFilter(filter: any) {
        this.updateFilters(filter);
    }

    public updateFilters(filters: any = {}) {
        const updatedFilters = this._cleanFilters({
            ...this._filters$.value,
            ...filters
        });
        this._filters$.next(updatedFilters);

        this._endReached$.next(false);
        this.load().subscribe();
    }

    private _cleanFilters(filters: any) {
        filters = this._utilities.filterEmptyFromObject(filters);
        filters = this._utilities.filterZerosFromObject(filters);
        filters = this._utilities.filterFalsesFromObject(filters);

        return filters;
    }

    private _removeUnnecessaryKeys(filters: any) {
        const newParams: { [key: string]: any } = {};

        Object.keys(filters)
            .forEach((key) => {
                if (key === 'major'
                    || key === 'team'
                    || key === 'high_school') {
                    const { id } = filters[key];
                    newParams[key + '_id'] = +id;

                } else if (key === 'college'
                    || key === 'sport'
                    || key === 'group'
                    || key === 'company'
                    || key === 'interested_company') {

                    const { id } = filters[key];
                    newParams[key + '_ids'] = [+id];

                } else if (key === 'industries') {
                    if (Array.isArray(filters['industries'])) {
                        newParams['industry_ids'] = filters?.industries?.map((i: Industry) => i.id) || null;

                    } else {
                        newParams['industry_ids'] = [filters['industries'].id];
                    }

                } else {
                    newParams[key] = filters[key];
                }
            });

        return newParams;
    }

    public exportUsers(usersIds: Array<number> | null) {
        const params: {
            team_id: number,
            user_ids?: Array<number>
        } = {
            team_id: this._team.id,
        };

        if (usersIds) {
            params['user_ids'] = usersIds;
        }

        return this._http
            .post<string>(ENDPOINTS.exportUsers, params);
    }

    public sendMessage(usersIds: Array<number> | null, message: string) {
        const params: {
            team_id: number,
            message: string,
            user_ids?: Array<number>
        } = {
            team_id: this._team.id,
            message
        };

        if (usersIds) {
            params['user_ids'] = usersIds;
        }

        return this._http
            .post(ENDPOINTS.sendPrivateMessages, params);

    }

}
