import { Team, TeamType, UnregisteredTeam } from '@athlete-x/definitions';

export class TeamHelper {
    public static teamType(team: Team | UnregisteredTeam | any): TeamType {
        if (team?.college) {
            return TeamType.COLLEGE;
        }
        if (team?.high_school) {
            return TeamType.HIGH_SCHOOL;
        }
        return TeamType.GROUP;
    }
}
