export const FIELD_REQUIRED = 'This field is required';
export const CONTACT_US = "Here's your help";
// export const EMAIL_PATTERN = '[A-Za-z0-9._%+-]{2,}@[a-zA-Z]{2,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})';
export const EMAIL_PATTERN = '[A-Za-z0-9._%+-]{2,}@[a-zA-Z0-9._-]{2,}[.]{1}[a-zA-Z]{2,}';

export const MONTHS = [
    {
        name: 'January',
        value: 1
    },
    {
        name: 'February',
        value: 2
    },
    {
        name: 'March',
        value: 3
    },
    {
        name: 'April',
        value: 4
    },
    {
        name: 'May',
        value: 5
    },
    {
        name: 'June',
        value: 6
    },
    {
        name: 'July',
        value: 7
    },
    {
        name: 'August',
        value: 8
    },
    {
        name: 'September',
        value: 9
    },
    {
        name: 'October',
        value: 10
    },
    {
        name: 'November',
        value: 11
    },
    {
        name: 'December',
        value: 12
    }
];
