import { APP_INITIALIZER, Inject, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { DEMO, PRODUCTION } from '@athlete-x/definitions';
import { environment } from '@athlete-x/env/environment';
import { API_BASE_ENDPOINT, ApiBaseInterceptor, ApiErrorInterceptor, IS_MOBILE, PaginationInHeadersInterceptor, SharedModule } from '@athlete-x/shared';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BASE_AUTH_PATH } from '@athlete-x/features/auth/services/is-authenticated.guard';
import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { InitializerService } from './services/initializer.service';
import { JsonApiInterceptor } from '@athlete-x/shared';
import { AuthInterceptor } from '@athlete-x/features/auth/services/auth.interceptor';
import { getRoutes, hasPotentialTeamSlug } from './routes';
import { POST_AUTH_ROUTE } from '@athlete-x/features/auth/definitions';
import { AuthenticatedComponent } from './containers/authenticated/authenticated.component';
import { GOOGLE_MAPS_KEY } from '@athlete-x/forms/address-autocomplete/services/google-maps.service';
import { isGeneralDomain } from '@athlete-x/shared/classes/DomainHelper';
import { SidebarModule, UserAvatarModule } from '@athlete-x/ui';
import { DirectoryModule } from '@athlete-x/features/directory';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ALLOW_REGISTRATION } from '@athlete-x/features/auth/tokens';

const imports = [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(
        {
            mode: 'md'
        }
    ),
    RouterModule.forRoot(getRoutes(), {
        enableTracing: false
    }),
    NgScrollbarModule,
    SharedModule,
    SidebarModule,
    DirectoryModule,
    UserAvatarModule
];

@NgModule({
    declarations: [AppComponent, AuthenticatedComponent],
    imports,
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: function Initialize(initializer: InitializerService) {
                return () => initializer.initialize$();
            },
            deps: [InitializerService],
            multi: true
        },
        {
            provide: APP_BASE_HREF,
            useFactory: () => {
                if (isGeneralDomain() && globalThis.TEAM_SLUG && hasPotentialTeamSlug()) {
                    return '/' + globalThis.TEAM_SLUG;
                }

                return '';
            },
        },
        {
            provide: ALLOW_REGISTRATION,
            useFactory: () => hasPotentialTeamSlug(),
        },
        {
            provide: LOCALE_ID,
            useValue: 'en-US'
        },
        {
            provide: BASE_AUTH_PATH,
            useValue: '/auth'
        },
        {
            provide: POST_AUTH_ROUTE,
            useValue: '/'
        },
        {
            provide: PRODUCTION,
            useValue: environment.production
        },
        {
            provide: DEMO,
            useValue: environment.demo
        },
        {
            provide: API_BASE_ENDPOINT,
            useValue: environment.APIEndPoint
        },
        {
            provide: GOOGLE_MAPS_KEY,
            useValue: environment.googleMapsApiKey
        },
        {
            provide: IS_MOBILE,
            useValue: false
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiBaseInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: PaginationInHeadersInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JsonApiInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        @Inject(DOCUMENT) private document: Document,
    ) {
        if (environment.hotjarSiteId) {
            (function(h: any, o: any, t: any, j: any, a?: any,r?: any){
                h.hj = h.hj ||
                // eslint-disable-next-line prefer-rest-params
                function(){(h.hj.q=h.hj.q||[]).push(arguments);};
                h._hjSettings={ hjid:environment.hotjarSiteId, hjsv:6 };
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window as any, this.document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        }
    }

}
