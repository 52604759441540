import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';

import { RouterModule } from '@angular/router';

import { CompaniesListComponent } from './components/companies-list/companies-list.component';
import { JobPickerModule } from '../job-picker';
import { AutocompletePickerModule } from '../autocomplete-picker';
import { EditCompanyModalComponent } from './components/edit-company-modal/edit-company-modal.component';
import { EditCompanyFormComponent } from './components/edit-company-form/edit-company-form.component';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        JobPickerModule,
        AutocompletePickerModule
    ],
    declarations: [
        CompaniesListComponent,
        EditCompanyModalComponent,
        EditCompanyFormComponent
    ],
    exports: [
        CompaniesListComponent,
        EditCompanyModalComponent,
        EditCompanyFormComponent
    ]
})
export class CompaniesListModule {}
