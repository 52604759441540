import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, switchMap } from 'rxjs';
import { UtilitiesService } from '@athlete-x/shared';
import { Industry, TeamFiltersFills, UsersTypesLables, UserType } from '@athlete-x/definitions';
import { TeamStore } from '@athlete-x/stores';

const LOCATIONS: Array<string> = [
    'Baltimore',
    'Philadelphia',
    'New York',
    'San Francisco'
];

@Injectable({
    providedIn: 'root'
})
export class FilterHelpersService {

    private _isGlobal$ = new ReplaySubject<boolean>();
    public isGlobal$ = this._isGlobal$.asObservable();

    public filters$: Observable<TeamFiltersFills> = this._isGlobal$
        .pipe(
            switchMap((isGlobal) => isGlobal
                ? this._getDefaultFilters()
                // : this._getDefaultFilters()
                : this._teamStore.filters$
            )
        );

    public locations$ = this.filters$
        .pipe(map(({ locations }) => locations));

    public industries$ = this.filters$
        .pipe(map(({ industries }) => industries));

    public gradYears$ = this.filters$
        .pipe(map(({ grad_years }) => grad_years));

    public usersTypes$ = this.filters$
        .pipe(map(({ user_types }) => !user_types.length
            ? []
            : user_types
                .map((e) => ({
                    value: e,
                    label: UsersTypesLables[e]
                }))
        ));

    constructor(
        private _http: HttpClient,
        private _utilities: UtilitiesService,
        private _teamStore: TeamStore
    ) {
    }

    public init(global: boolean) {
        this._isGlobal$.next(global);
    }

    private _getDefaultFilters() {
        return this._http.get<Array<Industry>>('industries/filter')
            .pipe(
                map((industries) => (
                    {
                        grad_years: this._utilities.makeClasses(),
                        locations: LOCATIONS,
                        industries,
                        user_types: Object.values(UserType)
                    }
                )));
    }
}
