import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'linkToIconName'
})
export class LinkToIconNamePipe implements PipeTransform {

    transform(value: string | null | undefined): string {

        if (!value) {
            return 'earth-outline';
        }

        if (value.indexOf('facebook') >= 0) {
            return 'logo-facebook';
        } else if (value.indexOf('twitter') >= 0) {
            return 'logo-twitter';
        } else if (value.indexOf('instagram') >= 0) {
            return 'logo-instagram';
        } else if (value.indexOf('youtube') >= 0) {
            return 'logo-youtube';
        }

        return 'earth-outline';
    }

}
