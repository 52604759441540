<ion-avatar *ngIf="avatar; else teamAvatar">
    <img [src]="avatar" alt="" />
</ion-avatar>

<ng-template #teamAvatar>
    <ng-container *ngIf="vm$ | async as vm">

        <ion-icon *ngIf="vm?.isGlobal; else teamLogo" name="globe-outline" class="opacity-40"></ion-icon>

        <ng-template #teamLogo>
            <ion-avatar *ngIf="vm.logoLink; else withoutAnyImage" class="opacity-40">
                <img [src]="vm.logoLink" alt="" />
            </ion-avatar>
            <ng-template #withoutAnyImage>
                <ion-icon name="person-circle-outline" class="opacity-40"></ion-icon>
            </ng-template>
        </ng-template>

    </ng-container>
</ng-template>
