import { FormGroup, ValidationErrors } from '@angular/forms';
import { TeamType } from '@athlete-x/definitions';

export const hasTeamId = (formGroup: FormGroup): ValidationErrors => {
    const highSchoolId = formGroup.get('high_school_id');
    const collegeId = formGroup.get('college_id');
    const groupId = formGroup.get('group_id');

    const teamType = formGroup.get('team_type');

    if ((highSchoolId?.value && teamType?.value === TeamType.HIGH_SCHOOL) ||
        (collegeId?.value && teamType?.value === TeamType.COLLEGE) ||
        (groupId?.value && teamType?.value === TeamType.GROUP)) {
        return {};

    } else {
        return {
            noTeamId: true
        };
    }
};
