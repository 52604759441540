import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImageCropperModalComponent } from '../components/image-cropper-modal/image-cropper-modal.component';
import { AspectRatioTypes, CompresionTypes } from '@athlete-x/definitions';

@Injectable({
    providedIn: 'root'
})
export class ImageCropperService {
    constructor(
        public modalController: ModalController
    ) {
    }

    public openCropper(base64: string, aspectRatioType: AspectRatioTypes, compression: CompresionTypes) {
        return this.modalController
            .create({
                component: ImageCropperModalComponent,
                cssClass: 'my-custom-class',
                componentProps: {
                    base64,
                    aspectRatioType,
                    compression
                }
            })
            .then(modal => {
                modal.present();
                return modal.onDidDismiss();
            });
    }

    public cropToBase64(base64: string, aspectRatioType: AspectRatioTypes, compression: CompresionTypes = CompresionTypes.PNG) {
        return this.openCropper(base64, aspectRatioType, compression)
            .then((cropperData) =>  cropperData.data?.base64);
    }
}
