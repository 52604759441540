import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IndustriesPickerComponent } from './industries-picker';
import { IonicModule } from '@ionic/angular';

@NgModule({
    declarations: [
        IndustriesPickerComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        IndustriesPickerComponent
    ]
})
export class IndustriesPickerModule {
}
