import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {jsonapi} from '../classes/jsonapi';
import {map} from 'rxjs/operators';

@Injectable()
export class JsonApiInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<never>, next: HttpHandler) {
        return next.handle(req)
            .pipe(map((response) => {
                const result = response as any;
                if (this._canbeJsonApi(result)) {
                    if (result.body.data?.data) {
                        result.body = this._nestedDataDeserialization(result.body);


                    } else {
                        result.body = this._standardDeserialization(result.body);
                    }

                }

                return result;
            }));
    }

    private _canbeJsonApi(data: { body: { data: never } }) {
        return data.body && data.body.data;
    }

    private _isJsonApiData(parsed: { jsonapi: { parsed: boolean } }) {
        return parsed.jsonapi && parsed.jsonapi.parsed === true;
    }

    private _standardDeserialization(body: any) {
        const parsed = jsonapi.parse(body);
        if (this._isJsonApiData(parsed)) {
            if (Object.keys(parsed).length > 2) {
                return parsed;

            } else if (parsed.data.type || Array.isArray(parsed.data)) {
                return parsed.data;
            }
        }

        return body;
    }

    private _nestedDataDeserialization(body: { data: { data: never } } ) {
        const { data, ...rest } = body.data;
        const standarizedBody = {
            ...body,
            data: body.data.data
        };
        const parsed = jsonapi.parse(standarizedBody);
        if (this._isJsonApiData(parsed)) {
            return {
                ...parsed,
                data: {
                    data: parsed.data,
                    ...rest
                }
            };
        }

        return body;
    }

}
